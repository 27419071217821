import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    container: {
        [theme.breakpoints.down('md')]: {
            overflow: "hidden"
        }  
    },
    cartProductCount: {
        paddingTop: "36px",
        paddingBottom: "36px",
        [theme.breakpoints.down('xs')]:{
            paddingTop: "16px",
            paddingBottom: "16px",
        }
    },
    orderSummaryCard: {
        padding: "16px 24px",
        paddingBottom: "12px !important",
        [theme.breakpoints.down('md')]: {
            padding: "16px 16px",
            paddingBottom: "8px !important",
        }
    },
    orderSummaryCardTitle: {
        paddingBottom: "16px"
    },
    orderSummaryLabel: {
        minWidth: '80px'
    },
    orderItem: {
        paddingTop: '12px',
        paddingBottom: '12px'
    },
    orderItemImage: {
        objectFit: "cover",
        borderRadius: '8px',
        backgroundColor: theme.palette.colors.fillStroke[300],
    },
    orderItemDescription: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    orderItemContent: {
        flexGrow: 1,
        flexBasis: 0,
        maxWidth: 'calc(100% - 88px)' //88 img (64x64 + 24 padding)
    },
    orderPriceOff: {
        textDecoration: 'line-through'
    },
    orderPriceDetailsCard: {
        padding: "16px 24px",
        paddingBottom: "24px !important",
        [theme.breakpoints.down('md')]: {
            padding: "16px 16px",
            paddingBottom: "16px !important",
        }
    },
    orderPriceDetails: {
        borderRadius: '0px 0px 8px 8px',
        borderTop: "4px solid",
        borderImageSource: "linear-gradient(273.67deg, rgb(252, 220, 67) 15.67%, rgb(255, 167, 0) 57.57%)",
        borderImageSlice: 1,
        background: theme.palette.colors.fillStroke[300],
        marginTop: "16px",
        padding: "16px 16px !important"
    },
    divideFullWidth: {
        backgroundColor: `${theme.palette.colors.grey[700]}`,
        marginLeft: '-24px',
        marginRight: '-24px',
    },
    orderSavings: {
        marginTop: '8px',
        padding: '4px 16px',
    },
    orderMonthlyPayments: {
        marginTop: '8px',
        padding: '4px 16px',
    },
    orderNextPaymentDate: {
        marginTop: '8px',
        padding: '4px 16px',
        borderRadius: '4px',
        background: theme.palette.colors.fillStroke[300],
    },
    paymentMethodItem: {
        fontSize: '14px',
        marginBottom: '14px'
    },
    paymentMethodItemLabel: {
        marginLeft: '15px' // + 9px padding radio input
    },
    paymentMethodDisabled: {
        color: theme.palette.colors.darkBlack[200]
    },
    recurringPaymentDate: {
        borderRadius: "8px",
        border: `1px solid ${theme.palette.colors.fillStroke[300]}`,
        background: `${theme.palette.colors.fillStroke[400]}`,
        maxWidth: '536px',
        padding: "16px",
        gap: "10px",
    },
    paymentMethodItemSkeleton: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        padding: "8px 0px",
    },
    // cardModal: {
    checkoutModal: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'auto',
        paddingLeft: '104px',
        paddingRight: '104px',
        border: '0.5px solid #404150',
        borderRadius: '8px',
        "@media (max-width: 510px)": {
            paddingLeft: '10px',
            paddingRight: '10px'
        }
    },
    // cardModalInside: {
    checkoutModalInside: {
        "@media (max-width: 510px)": {
            paddingLeft: '10px',
            paddingRight: '10px'
        }
    },
    checkoutModalContent: {
        width: 650,
        backgroundColor: '#111111',
        textAlign: 'center',
        paddingLeft: '100px',
        paddingRight: '100px',
        paddingTop: '40px',
        paddingBottom: '30px',
        borderRadius: '8px'
    },
    /** newCard-modal */
    checkoutModalProceedButton: {
        fontSize: '16px',
        color: 'white',
        fontWeight: '600',
        width: '260px',
        textTransform: 'none',
        "@media (max-width: 510px)": {
            width: '100%'
        }
    },
    /** paymentConfirmation-modal */
    paymentConfirmationModal: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'auto',
        paddingTop: '58px',
        paddingLeft: '104px',
        paddingRight: '107.14px',
        border: '0.5px solid #404150',
        borderRadius: '8px',
        "@media (max-width: 510px)": {
            paddingLeft: '10px',
            paddingRight: '10px'
        }
    },
    paymentConfirmationModalInside: {
        "@media (max-width: 510px)": {
            paddingLeft: '10px',
            paddingRight: '10px'
        }
    },
    /** PaymentProcessingModal-modal */
    paymentProcessingModal: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'auto',
        paddingTop: '58px',
        paddingLeft: '104px',
        paddingRight: '107.14px',
        border: '0.5px solid #404150',
        borderRadius: '8px',
        "@media (max-width: 510px)": {
            paddingLeft: '10px',
            paddingRight: '10px'
        }
    },
    paymentProcessingModalInside: {
        "@media (max-width: 510px)": {
            paddingLeft: '10px',
            paddingRight: '10px'
        }
    },
    summaryContainer: {
        marginBottom: "72px",
    }
}));