import React from "react";
import clsx from "clsx";
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

//@Material-ui
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import CardContent from '@material-ui/core/CardContent';
//Styles
import useStyles from "../../../../assets/styles/jss/pages/memberships/versionSKU/MembershipModalUpSelling/MembershipModalUpSellingStyle";
//Components
import MembershipCardUpSellingMain from './components/MembershipCardUpSellingMain';
import MembershipCardUpSellingSecondary from './components/MembershipCardUpSellingSecondary';
//Design-system
import Loader from '../../../../design-system/components/Loader/LoaderContent';
//CartHelper Shared
import { addProductsToCart } from "../../../../shared/utils/cartHelper";
import { eventTracker } from "../../../../shared/components/EventTracker/EventTracker.js";
import { compareProductPeriods, getNameMembership, getPeriodFromSKU, getPeriodMembership, getProductType, replaceSKUPeriod } from "../../../../shared/utils/checkoutHelpers.js";
//Redux
import { applyCart, applyCartProducts } from "../../../../redux/actions/cartActions";

import { log } from '../../../../shared/utils/console.js'
// routes
import * as paths from '../../../../routes/paths'
// SDK
import { ProductService } from "@sdk-point/talisis";
// utils
import { userIsLogged } from "../../../../shared/utils/Sessions.js";
const ProductServiceSDK = new ProductService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const TITLE_MODAL = 'Mejora tu plan';
const MAXIMUM_SAVINGS_PORCENT = '25%';

const MembershipModalUpSelling = ({ toOpen, setToOpen, membershipSelected, additionalProduct, productAddOn, feeSelectedShow, feeBase }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const isLogged = userIsLogged();
    const { cart } = useSelector((state) => state.cartReducer);
    const { user } = useSelector((state) => state.userReducer);
    const [loading, setLoading] = React.useState(false);
    const feeSelected = membershipSelected.fees.find(fee => fee?.months === feeSelectedShow?.months);
    const otherFeesOptionsShow = membershipSelected.fees.filter(fee => fee?.months > feeSelectedShow?.months);
    const handleDialogClose = () => {
        setToOpen(!toOpen);
    }

    const continueToCart = async (product1) => {
        // Agregar membresía
        const products = [{
            "product_id": product1?.product_id,
            "product_name": product1?.product_name,
            "product_image_url": product1?.product_image_url,
            "product_description": product1?.product_description,
            "product_type_id": product1?.product_type_id,
            "quantity": 1,
            "product_price_id": product1?.product_price_id,
            "unit_price": product1?.list_price,
            "discount": 0
        }];
        // Event tracker membresía 
        if (!additionalProduct && !productAddOn) {
            handleMembershipPurchaseEventTrack(product1);
        }
        // Si hay un producto adicional como un programa o curso, agregarlo al carrito
        if (additionalProduct) {
            products.push({
                "product_id": additionalProduct?.product_id,
                "product_name": additionalProduct?.product_name,
                "product_image_url": additionalProduct?.product_image_url,
                "product_description": additionalProduct?.product_description,
                "product_type_id": additionalProduct?.product_type_id,
                "quantity": 1,
                "product_price_id": additionalProduct?.product_price_id,
                "unit_price": additionalProduct?.list_price,
                "discount": 0
            });
            // Event tracker membresía + curso
            handleMembershipCourseEventTrack(product1, additionalProduct);
        }
        // Si hay un producto add-on como harmon hall, agregarlo al carrito
        if (productAddOn) {
            let addOnInfo = productAddOn;
            // Validar si el poducto addOn y la membresía son compatibles
            const areProductsCompatible = compareProductPeriods(product1.product_sku, addOnInfo.product_sku);
            if (!areProductsCompatible) {
                const membershipPeriod = getPeriodFromSKU(product1.product_sku);
                const newAddonSKU = replaceSKUPeriod(addOnInfo.product_sku, membershipPeriod);
                const response = await ProductServiceSDK.getProductsBySkuPattern(newAddonSKU);
                if (response.length > 0) {
                    addOnInfo = ({
                        product_id: response[0]?.id,
                        product_name: response[0]?.name,
                        product_image_url: response[0]?.image_url,
                        product_description: response[0]?.description,
                        product_category_id: response[0]?.category_id,
                        product_type_id: response[0]?.product_type_id,
                        product_sku: response[0]?.sku,
                        quantity: 1,
                        product_price_id: response[0]?.product_price_id,
                        unit_price: response[0]?.list_price,
                        discount: 0
                    });
                }
            }
            products.push({
                "product_id": addOnInfo?.product_id,
                "product_name": addOnInfo?.product_name,
                "product_image_url": addOnInfo?.product_image_url,
                "product_description": addOnInfo?.product_description,
                "product_type_id": addOnInfo?.product_type_id,
                "quantity": 1,
                "product_price_id": addOnInfo?.product_price_id,
                "unit_price": addOnInfo?.unit_price,
                "discount": 0
            });
            // Event tracker membresía + addon
            handleMembershipAddonEventTrack(product1, addOnInfo);
        }
        // Si hay un producto adicional como un programa o curso, agregarlo al carrito
        if (additionalProduct) {
            products.push({
                "product_id": additionalProduct?.product_id,
                "product_name": additionalProduct?.product_name,
                "product_image_url": additionalProduct?.product_image_url,
                "product_description": additionalProduct?.product_description,
                "product_type_id": additionalProduct?.product_type_id,
                "quantity": 1,
                "product_price_id": additionalProduct?.product_price_id,
                "unit_price": additionalProduct?.list_price,
                "discount": 0
            });
        }
        // Agregar productos a carrito vía api
        let response = await addProductsToCart({
            cart_id: cart.id,
            products: [...products]
        });
        // Actualizar estado reducer del carrito
        dispatch(applyCart(response))
        dispatch(applyCartProducts(response.cart_details))
    };

    const handleMembershipPurchaseEventTrack = (product) => {
        try {
            const eventData = {};
            eventData['nombreArticulo'] = product?.product_name;
            eventData['precioArticulo'] = product?.list_price;
            eventData['articuloType'] = "membresías";
            eventData['subscriptionType'] = getNameMembership(product?.product_name);
            eventData['periodoPago'] = getPeriodMembership(product?.product_sku);
            eventData['source'] = 'planes';
            if (isLogged) {
                eventData['userId'] = user?.matricula || user?.person_id || "";
                eventTracker('agregar_membresia', eventData, ['mixpanel', 'hubspot', 'google']);
            } else {
                eventTracker('agregar_membresia_anonimo', eventData, ['mixpanel', 'google']);
            }
        } catch (e) {
            log("event tracker error", e);
        }
    };

    const handleMembershipCourseEventTrack = (product1, product2) => {
        try {
            const eventData = {};
            eventData['nombreArticulo'] = product2?.product_name;
            eventData['precioArticulo'] = product1?.list_price;
            eventData['articuloType'] = getProductType(product2?.product_type_id);
            eventData['subscriptionType'] = getNameMembership(product1?.product_name);
            eventData['periodoPago'] = getPeriodMembership(product1?.product_sku);
            eventData['source'] = 'planes';
            if (isLogged) {
                eventData['userId'] = user?.matricula || user?.person_id || "";
                eventTracker('agregar_membresia_curso', eventData, ['mixpanel', 'hubspot', 'google']);
            } else {
                eventTracker('agregar_membresia_curso_anonimo', eventData, ['mixpanel', 'google']);
            }
        } catch (e) {
            log("event agregar_membresia_curso error", e);
        }
    };

    const handleMembershipAddonEventTrack = (product1, productAddon) => {
        try {
            const eventData = {};
            eventData['nombreArticulo'] = productAddon?.product_name;
            eventData['precioArticulo'] = productAddon?.unit_price + product1?.list_price;
            eventData['articuloType'] = "addOn"
            eventData['subscriptionType'] = getNameMembership(product1?.product_name);
            eventData['source'] = 'planes';
            if (isLogged) {
                eventData['userId'] = user?.matricula || user?.person_id || "";
                eventTracker('agregar_membresia_addon', eventData, ['mixpanel', 'hubspot', 'google']);
            } else {
                eventTracker('agregar_membresia_addon_anonimo', eventData, ['mixpanel', 'google']);
            }
        } catch (e) {
            log("event agregar_membresia_addon error", e);
        }
    };

    const handleContinueClick = async () => {
        try {
            setLoading(true);
            // Agregar los productos al carrito
            await continueToCart(feeSelected);
            // Redireccionar al carrito para ver el detalle.
            history.push(paths.CART);
            setLoading(false);
        } catch (e) {
            log("error al agregar productos al carrito", e);
        }
    };

    const handleUpsellingClick = async (fee) => {
        try {
            setLoading(true);
            // Agregar los productos al carrito
            await continueToCart(fee);
            // Redireccionar al carrito para ver el detalle.
            history.push(paths.CART);
            setLoading(false);
        } catch (e) {
            log("error al agregar productos al carrito", e);
        }
    };

    return (
        <Modal
            open={toOpen}
            className={classes.modal}>
            <Card className={classes.cardModal}>
                <Loader loading={loading}>
                    <Box className={classes.loaderContainer}>
                        <CardHeader className="pb-0"
                            action={
                                <IconButton aria-label="settings" onClick={handleDialogClose}>
                                    <Icon className={clsx(["ri-close-line", classes.iconCloseModal])} />
                                </IconButton>
                            }
                            title={
                                <Typography variant='h3' className={clsx('text-gray-200')}>
                                    {TITLE_MODAL}
                                </Typography>
                                }
                        />
                        <CardContent className="text-center pb-0" >
                            <Grid container justifyContent="center">
                                <MembershipCardUpSellingMain membershipSelect={membershipSelected} feeShow={feeSelectedShow} />
                            </Grid>
                            <Typography variant="subtitle1" className={classes.modalSubTitle}>
                                Ahorra hasta un {MAXIMUM_SAVINGS_PORCENT}<br />
                                con nuestras opciones de prepago
                            </Typography>
                            <Grid container justifyContent="center">
                                {
                                    otherFeesOptionsShow.map((feeOptionShow, index) => {
                                        return (
                                            <MembershipCardUpSellingSecondary key={index} feeOptionShow={feeOptionShow} feeBase={feeBase} onUpselling={handleUpsellingClick} />
                                        )
                                    })
                                }
                            </Grid>
                        </CardContent>
                        <Grid container justifyContent="center">
                            <Button color='primary' endIcon={<Icon className='ri-arrow-right-line' style={{ fontSize: '24px !important' }} />} onClick={() => { handleContinueClick() /*alert('Dirigiendo al carrito-> Próximamente')*/ }}>Continuar con pago {feeSelectedShow?.feeName?.toLowerCase()}</Button>
                        </Grid>
                    </Box>
                </Loader>
            </Card>
        </Modal>
    )
}

export default MembershipModalUpSelling;