import React from 'react';
import CurrencyFormat from 'react-currency-format';
import clsx from 'clsx';

// @material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useMediaQuery } from '@material-ui/core';

const OrderBonuses = ({ orderBonuses }) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    return <Grid container justifyContent="space-between" alignItems='center' className='mt-1'>
        <Grid item>
            <Typography variant={isMobile ? "caption" : "body2"}>Bonificación por suscripción anterior:</Typography>
        </Grid>
        <Grid item>
            <Typography variant="body2" className={clsx("text-dark-black-100", "font-weight-500", { "font-size-12": isMobile })}>
                <CurrencyFormat value={orderBonuses} decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" suffix=" MXN" />
            </Typography>
        </Grid>
    </Grid>
};

export default OrderBonuses;