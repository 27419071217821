import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { useHistory, useLocation, Link as RouterLink } from "react-router-dom";
import { useGoogleLogin } from 'react-google-login'
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import AuthScreen from './AuthScreen/AuthScreen';
import { getSessionCookie } from "../../../../shared/utils/Sessions";

// redux
import { connect } from 'react-redux';
import * as userActions from '../../../../redux/actions/userActions';
import * as menuActions from '../../../../redux/actions/menuActions';
import * as profilePicActions from '../../../../redux/actions/profilePicActions';
import * as tourInitialActions from '../../../../redux/actions/tourInitialActions';

//services
import { setSessionCookie } from '../../../../shared/utils/Sessions';
import { normalizeError } from '../../../../shared/utils/MSUtils';
import { Mixpanel } from '../../../../shared/components/Mixpanel/mixpanel';
import { loginRequest } from '../../../../config/authConfig';
import { log } from '../../../../shared/utils/console.js'
import { eventTracker, eventData } from '../../../../shared/components/EventTracker/EventTracker';
import * as paths from '../../../../routes/paths';

import { MEMBERSHIPS } from '../../../../shared/constants/memberships';

import { makeStyles } from '@material-ui/core/styles';

// SDK
import { LoginService, MIXPANEL_EVENT, SingupService, UserService } from '@sdk-point/talisis';
import { Divider, Icon, Link, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';


//Sentry
import * as Sentry from "@sentry/react";
import { getCheckoutCookie, setCheckoutCookie } from '../../../../shared/utils/checkoutSession-legacy.js';

// Checkout sku
import { clearCartCookie } from '../../../../shared/utils/cartSession.js';
import { clearCheckoutCookie } from '../../../../shared/utils/checkoutSession.js';

const LoginServiceSDK = new LoginService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const UserServiceSDK = new UserService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const SingupServiceSDK = new SingupService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const SplitLogin = props => {
    const session = getSessionCookie();
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [sendActivation, setSendActivation] = useState({disabled: true, message: 'Volver a enviar en... ', resended:false, lastStep:false});
    const [seconds, setSeconds] = useState(20);
    const [cropEmail, setCropEmail] = useState('')
    const { signupData, onOpenAutoSignup, onOpenForm, applyUser, onLoading, setLoading,loading, onLoginError, getMail, onResetPassword, setStatusLogin, statusLogin, handleGoBack } = props;
    const [userState, setUserState] = useState({
        isAuthenticated: false,
        user: {},
        error: null
    });
    const [company, setCompany] = useState({
        sso: '',
        ou: '',
        client_id: '',
        ouLabel: '',
        ouId: ''
    });

    let history = useHistory();
    let location = useLocation();
    const [errors, setErrors] = React.useState({});
    const [form, setForm] = React.useState({
        'email': '',
    });

    const preventDefault = (event) => event.preventDefault();

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value) {
            const _errors = { ...errors };
            delete _errors[name];
            setErrors(_errors);
        }

        setForm(prev => { 
            let clean_value = value.trim();
            if(name == "email"){
                clean_value = clean_value.toLowerCase(); 
            }
            return { ...prev, [name]: clean_value }
         });
    };

    const validate = () => {
        const _errors = {};
        const requiredFields = ['email'];
        var pattern = new RegExp(/^[\w.%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
    
        for (const field of requiredFields) {
            if (!form[field]) {
                _errors[field] = 'Campo Requerido';
            } else {
                if (!pattern.test(form[field])) {
                    _errors[field] = 'Introduce un mail válido';
                }
            }
        }

        setErrors({ ..._errors });
        if (Object.entries(_errors).length !== 0) {
            errorReportMixpanel(_errors);
        }

        return Boolean(Object.keys(_errors).length === 0);
    };


    const openSSO = {
        'red.unid.mx': () => setCompany({ sso: 'microsoft', ou: 'unid', client_id: 'unid', ouLabel: 'UNID', ouId: 'UNID', domainHint:'red.unid.mx' }),
       // 'unid.mx': () => setCompany({ sso: 'microsoft', ou: 'unid', client_id: 'unidStaff', ouLabel: 'UNID', ouId: 'UNID', domainHint:'unid.mx' }),
        'unid.mx': () => setCompany({ sso: 'microsoft', ou: 'talisis', client_id: 'talisis', ouLabel: 'Talisis', ouId: 'TAL', domainHint:'talisis.com' }),
        'u-erre.mx': () => setCompany({ sso: 'microsoft', ou: 'uerre', client_id: 'uerre', ouLabel: 'U-ERRE', ouId: 'UERRE', domainHint:'u-erre.mx' }),
        'u-erre.edu.mx': () => setCompany({ sso: 'microsoft', ou: 'uerre', client_id: 'talisis', ouLabel: 'U-ERRE', ouId: 'UERRE', domainHint:'u-erre.edu.mx' }),
        'iesalud.mx': () => setCompany({ sso: 'microsoft', ou: 'iesalud', client_id: 'uerre', ouLabel: 'IESALUD', ouId: 'IESALUD', domainHint:'iesalud.mx' }),
        'iesalud.edu.mx': () => setCompany({ sso: 'microsoft', ou: 'iesalud', client_id: 'uerre', ouLabel: 'IESALUD', ouId: 'IESALUD', domainHint:'iesalud.edu.mx' }),
        'talisis.com': () => setCompany({ sso: 'microsoft', ou: 'talisis', client_id: 'talisis', ouLabel: 'Talisis', ouId: 'TAL', domainHint:'talisis.com' }),
        'miadvenio.com': () => setCompany({ sso: 'microsoft', ou: 'advenio', client_id: 'miAdvenio', ouLabel: 'Advenio', ouId: 'ADV', domainHint:'miadvenio.com' }),
        'harmonhall.edu.mx': () => setCompany({ sso: 'microsoft', ou: 'harmonhall', client_id: 'talisis', ouLabel: 'Harmon Hall', ouId: 'HH', domainHint:'talisis.com' }),
        'al.harmonhall.edu.mx': () => setCompany({ sso: 'google', ou: 'harmonhall', client_id: 'uerre', ouLabel: 'Harmon Hall', ouId: 'HH', domainHint:'al.harmonhall.edu.mx' }),
        'soy.harmonhall.edu.mx': () => setCompany({ sso: 'microsoft', ou: 'harmonhall', client_id: 'soyHH', ouLabel: 'Harmon Hall', ouId: 'HH', domainHint:'soy.harmonhall.edu.mx'}),
        'doc.harmonhall.edu.mx': () => setCompany({ sso: 'microsoft', ou: 'harmonhall', client_id: 'soyHH', ouLabel: 'Harmon Hall', ouId: 'HH', domainHint:'soy.harmonhall.edu.mx'}),
        'familia.talisis.com': () => setCompany({ sso: 'microsoft', ou: 'talisis', client_id: 'talisis', ouLabel: 'Talisis', ouId: 'TAL', domainHint:'soy.harmonhall.edu.mx' }),
        'familia.advenio.com': () => setCompany({ sso: 'microsoft', ou: 'advenio', client_id: 'miAdvenio', ouLabel: 'Advenio', ouId: 'ADV', domainHint:'miadvenio.com' }),
        'default':() => setCompany({ sso: 'microsoft', ou: 'talisis', client_id: 'talisis', ouLabel: 'Talisis', ouId: 'TAL'}),
    }

    const msLogin = async () => {
        const comesFromCheckout = location?.state?.referrer;
        const req = {
            // scopes: loginRequest.scopes,
            prompt: "consent",
            account: { userName: form.email }, 
            loginHint: form.email, 
            domainHint: company?.domainHint,
            extraQueryParameters:{
                env: process.env.REACT_APP_ENV
            },
            // onRedirectNavigate: '',
            redirectStartPage: '/auth',
            // redirectUri: ''
        }
        const loginWithRedirect = () => {
            req.redirectStartPage = '/auth';
            instance.loginRedirect(req).catch(e => {
                log(e);
            })
        }

        try {
            (comesFromCheckout === "checkout-login-SSO") 
                ? instance.loginPopup(req).catch(e => {
                    log(e);
                })
                : loginWithRedirect();
        }
        catch (err) {
            setUserState({
                isAuthenticated: false,
                user: {},
                error: normalizeError(err)
            });
            onLoading(false)
            setCompany({})
        }
    }

    const responseGoogle = async response => {
        onLoading(true)
        const profile = await response.profileObj;
        let user = {
            userName: profile ? profile.name : '',
            email: profile ? profile.email : '',
            ou: company.ou,
            ouLabel: company.ouLabel,
            company_id: company.ouId
        }
        const profilePhoto = {
            src: profile?.imageUrl,
            blob: false
        }

        LoginServiceSDK.studentCode(user).then(async student => {
            if (student.profile_picture_url) {
                const profilePhoto = {
                    src: student.profile_picture_url,
                    blob: false
                };
                props.applyProfilePic(profilePhoto)
            } else {
                if(profile?.imageUrl) {
                    const profilePhoto = {
                        src: profile?.imageUrl,
                        blob: true
                    };
                    props.applyProfilePic(profilePhoto)
                }
            }
            if (student.error) {
                /* onLoginError('Por Favor completa el registro.')
                onLoading(false)
                log(student.error)
                signupData({
                    email: profile.email,
                    first_name: student.first_name,
                    last_name: student.last_name,
                    gender: student.gender,
                    profilePic: profilePhoto, ou: company.ou,
                    ouLabel: company.ouLabel,
                    company_id: company.ouId
                });
                onOpenAutoSignup(true); */
            }
            else {
                log(student)
                user.userName = `${student.first_name} ${student.last_name}`
                user.first_name = student.first_name
                user.last_name = student.last_name
                user.student_id = await student.student_id
                user.person_id = await student.person_id
                user.is_admin = await student.is_admin
                user.gender = await student.gender
                user.membership_id = await student.membership_id || MEMBERSHIPS.FREEMIUM
                user.interests = await student.interests || []
                if (await student.is_active) {
                    setSessionCookie(user);
                    applyUser(user);

                  
                    Mixpanel.people({
                        "$name": user.userName,
                        "$email": user.email
                    });
                    Sentry.setUser({ email: user.email,username: user.userName });
                  

                    var searchParams = new URLSearchParams(location.search);
                    if (searchParams.has('course') || searchParams.has('program')) {
                        history.replace({
                            pathname: paths.CHECKOUT_LEGACY,
                            state: {
                                'user': user,
                                'referrer': "google-login",
                                'items': [{
                                    course_id: searchParams.get('course'),
                                    program_id: searchParams.get('program')
                                }]
                            }
                        });
                    }
                    else {
                        // history.push('/');
                        const sessionCookie = getSessionCookie();
                            if(Object.keys(sessionCookie).length > 0){
                                const checkoutCookie = getCheckoutCookie()
                                setCheckoutCookie({
                                    ...checkoutCookie,
                                    personId: user.person_id,
                                    personInfo: user,
                                    anonymousSession: false,
                                    activeAccount: true
                                })
                                await history.replace({
                                    pathname: paths.CHECKOUT_LEGACY,
                                    state: {
                                        'user': user,
                                        'person': user,
                                        'referrer': "checkout-login-SSO-AQUI",
                                    }
                                });
                            }
                    }
                    
                    /* checkout sku */
                    clearCartCookie();
                    clearCheckoutCookie();

                    history.go(0);
                }
                else {
                    onLoginError('Por el momento no cuentas con acceso a la plataforma')
                    onLoading(false)
                }
            }
        });
    }

    const errorReportMixpanel = (_errors) => {
        const eventdata = {
            email: form['email'],
            mensaje: "ERROR: " + _errors.email
        }
       
    }

    const googleAuth = useGoogleLogin({
        clientId: '42382497242-v8scnc8la2v9h7jhkjpoovvhpiqi63sq.apps.googleusercontent.com', // Your clientID from Google.
        onSuccess: responseGoogle,
        loginHint: form.email,
        prompt: 'consent'
    });

    const handleResetPassword = () => {
        onResetPassword(true);
    };

    const handleSendActivation = async (email) => {
        try {
            if(!email){
                email=form.email
            }
            
            const payload = {
                 env: process.env.REACT_APP_ENV,
                 emails: [email]
            }
            await SingupServiceSDK.talisisResendActivation(payload)
        } catch (error) {
            log("Error al enviar correo de activación: ", error)
        }
    }

    const handleResendButton = async () => {
        handleSendActivation()
        setSendActivation({disabled: true, message: 'Correo de activación reenviado', resended: true});
    }

    const formatEmail = (email) => {
        const [localPart, domain] = email.split('@');
        if (localPart.length < 3) {
            // If the local part is too short to apply the formatting, return the email as is
            return email;
        }
        const firstPart = localPart.slice(0, 2);
        const lastPart = localPart.slice(-3);
        const maskedPart = '*'.repeat(localPart.length - 5);
        return `${firstPart}${maskedPart}${lastPart}@${domain}`;
    }

    const handleSubmit = async (e) => {
        preventDefault(e);
        setLoading(true)
        if (validate()) {
            //Validate register email and activate
            const dataEmail = await UserServiceSDK.getByEmail(form.email, true);
            if (dataEmail?.data?.length === 0) {
                setStatusLogin({status: 1, error: true})
                onLoading(false);
                return;
            }
            if (!dataEmail?.data[0]?.is_active) {
                await handleSendActivation(form.email)
                setCropEmail(formatEmail(form.email))
                setSeconds(20)
                setStatusLogin({status: 2, error: true, data: dataEmail?.data[0]})
                onLoading(false);
                return;
            }
    
            if (!dataEmail?.data[0]?.is_main || !dataEmail?.data[0]?.is_used_for_login) {
                const mainData = await UserServiceSDK.getMainEmail("person_id", dataEmail?.data[0]?.id)
                if(!mainData){
                    setStatusLogin({status: 1, error: true})
                    onLoading(false);
                    return
                }
                setCropEmail(formatEmail(mainData?.email_address))
                setStatusLogin({status: 3, error: true, data: dataEmail?.data[0]})
                onLoading(false);
                return;
            }
            // onLoading(true)
            var domain = form.email.substring(form.email.lastIndexOf("@") + 1);
             //openSSO[domain]
             (openSSO[domain] || openSSO['default'])();
        }else{
            setLoading(false)
        }
    };

   
/*
    async function obtenerRespuesta() {
        try {
          const response = await LoginServiceSDK.getSplitLogin(form.email);
          console.log(response);
          return response; // Devuelve la respuesta para que pueda ser utilizada fuera de esta función
        } catch (error) {
          console.error('Error al obtener la respuesta:', error);
          throw error; // Lanza el error para que pueda ser manejado por el código que llama a esta función
        }
      }
      

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) { 
            try {
                const response = await obtenerRespuesta();
                setCompany(response); // Establece el estado de la compañía con la respuesta obtenida
            } catch (error) {
                console.error('Error al obtener la respuesta:', error);
            }
        }
    };
*/   

    useEffect(() => {
        if (company.sso === 'microsoft') {
            msLogin()
        }

        if (company.sso === 'google') {
            googleAuth.signIn()
        }
        // }, [company, googleAuth, msLogin])
    }, [company]);

    useEffect(() => {
        if(statusLogin.status === 2){
            // If the countdown is active
            if (seconds > 0) {
                const timer = setInterval(() => {
                    setSeconds(prev => prev - 1);
                }, 1000);

                return () => clearInterval(timer);
            } else {
                // Enable the button when countdown finishes
                setSendActivation({disabled: false, message: 'Reenviar correo de activación'});
            }
        }
    }, [seconds,statusLogin]);

    const contactTechnicalSupport = () => {
        const talisisHelpDeskButton = document.getElementById('talisis_helpdesk_button');
        if (talisisHelpDeskButton) {
            talisisHelpDeskButton.click();
        }
    }

    return (
        <Box>
            <form onSubmit={handleSubmit}>
                        {
                            statusLogin.status === 0 ?
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                            className: 'text-gray-100 semi-bold'
                                        }}
                                        name="email"
                                        variant="outlined"
                                        label="Correo electrónico"
                                        fullWidth
                                        size="medium"
                                        // required
                                        value={form.email || ""}
                                        error={Boolean(errors.email)}
                                        helperText={errors.email}
                                        onChange={handleChange}
                                        placeholder="Ingresa tu correo electrónico"
                                        className="offset-margin-bottom-32"
                                        InputProps={{
                                            classes: {
                                                input: {
                                                    fontSize: '14px !important'
                                                }
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" className="offset-margin-bottom-32">
                                        <Button fullWidth variant="contained" color="primary" type={'submit'} onClick={handleSubmit}>Iniciar sesión</Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Link component="button" variant="caption" onClick={handleResetPassword} color="secondary">¿Olvidaste tu contraseña?</Link>
                                </Grid>
                            </Grid>
                            : statusLogin.status === 1 ?
                                <>
                                    <Box width={'100%'} mb={4} mt={4} display={'flex'} flexDirection={'column'} alignItems={'flex-start'} gridGap={8}>
                                        <Typography variant='body2' color='textSecondary' align='left'>El correo que has ingresado no se encuentra registrado en <br/> nuestro sistema. Por favor, verifica la dirección de correo y <br/> vuelve a intentarlo.</Typography>
                                        <Button 
                                        color='primary' 
                                        variant='contained' 
                                        style={{ marginBottom: 24, width: '100%', marginTop: 32 }}
                                        onClick={()=>{setSendActivation({disabled: true, message: 'Volver a enviar en... ', resended:false, lastStep:false})
                                                    handleGoBack()
                                                    }}
                                        >
                                            Intentar de nuevo
                                        </Button>
                                    </Box>
                                    <Divider className="offset-margin-top-32 bg-fill-stroke-200 offset-margin-bottom-11" variant="fullWidth" />
                                    <Box display="flex" justifyContent="center" mb={7}>
                                        <Typography variant="body1" color="textPrimary" className="mr-1">¿No tienes cuenta aún?</Typography>
                                        <Link component={RouterLink} variant="body1" color="secondary" to={'/signup' + location.search} onClick={() => {
                                            eventData['source'] = 'login';
                                            eventTracker('cta_registro', eventData, ['mixpanel', 'hubspot', 'google']);
                                        }}>
                                            Registrate aquí
                                        </Link>
                                    </Box>
                                </>
                            : statusLogin.status === 2 ?
                                (sendActivation.lastStep) ?
                                <Box width={'100%'} mb={8} display={'flex'} flexDirection={'column'} alignItems={'flex-start'} gridGap={8}>
                                    <Typography variant='h3'>¿No recibiste el correo?</Typography>
                                    <Typography variant='body2' align='left'>Lamentamos que no hayas recibido el correo de activación. <br/> Aquí tienes algunas sugerencias para solucionarlo:</Typography>
                                    <List disablePadding>
                                        <ListItem style={{padding: 0}} alignItems="flex-start">
                                            <ListItemIcon style={{ minWidth: '15px', marginTop: '10px' }}>
                                            <Icon className="ri-checkbox-blank-circle-fill" style={{ color: 'orange', fontSize: 5 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                            primary={<Typography variant='body2' style={{ color: 'orange' }}>Verifica tu carpeta de spam o correo no deseado.</Typography>}
                                            />
                                        </ListItem>
                                        <ListItem style={{padding: 0}} alignItems="flex-start">
                                            <ListItemIcon style={{ minWidth: '15px', marginTop: '10px' }}>
                                            <Icon className="ri-checkbox-blank-circle-fill" style={{ color: 'orange', fontSize: 5 }} />
                                            </ListItemIcon>
                                            <ListItemText
                                            primary={<Typography variant='body2' style={{ color: 'orange' }}>Asegúrate de que la dirección de correo electrónico proporcionada es correcta.</Typography>}
                                            />
                                        </ListItem>
                                    </List>
                                    <Typography variant='body2' align='left'>Si después de revisar estos pasos aún no has recibido el <br/> correo, por favor, contacta a nuestro equipo de soporte para <br/> asistencia adicional.</Typography>
                                    <Button 
                                    color='primary' 
                                    variant='contained' 
                                    style={{ marginBottom: 24, width: '100%', marginTop: 32 }}
                                    onClick={()=>contactTechnicalSupport()}
                                    >
                                        Contactar soporte
                                    </Button>
                                </Box>
                                :
                                <Box width={'100%'} mb={8} display={'flex'} flexDirection={'column'} alignItems={'flex-start'} gridGap={8}>
                                    <Typography variant='h4'>Hola, {statusLogin?.data && statusLogin?.data?.first_name}</Typography>
                                    <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                                    <Typography variant='body2' align='left'>No has activado tu cuenta, te hemos enviado un correo con los <br/> pasos de activación a la siguiente dirección:</Typography>
                                    <Typography variant='body2' className='semi-bold'>{cropEmail}</Typography>
                                    </Box>
                                    <Box mt={3} width={'100%'}>
                                        <Button fullWidth variant='contained' color='primary' disabled={sendActivation.disabled} onClick={()=>{handleResendButton()}}>
                                            {sendActivation.message} {seconds !== 0 && seconds}
                                        </Button>
                                    </Box>
                                    <Box mt={3} width={'100%'}>
                                        {
                                            sendActivation.resended &&
                                            <Button color='primary' onClick={()=>{setSendActivation({disabled: true, message: 'Correo de activación reenviado', resended: true, lastStep:true})}}>¿No recibiste el correo?</Button>
                                        }
                                    </Box>
                                </Box>
                            : statusLogin.status === 3 &&
                                <>
                                    <Box width={'100%'} mb={4} mt={4} display={'flex'} flexDirection={'column'} alignItems={'flex-start'} gridGap={8}>
                                        <Typography variant='h4'>Correo Incorrecto</Typography>
                                        <Typography variant='body2' color='textSecondary' align='left'>Para realizar esta acción, es necesario que utilices tu correo <br/> electrónico de acceso:</Typography>
                                        <br/>
                                        <Typography>{cropEmail}</Typography>
                                        <Button 
                                        color='primary' 
                                        variant='contained' 
                                        style={{ marginBottom: 24, width: '100%', marginTop: 32 }}
                                        onClick={()=> handleGoBack()}
                                        >
                                            Intentar de nuevo
                                        </Button>
                                    </Box>
                                    <Divider className="offset-margin-top-32 bg-fill-stroke-200 offset-margin-bottom-11" variant="fullWidth" />
                                    <Box display="flex" justifyContent="center" mb={7}>
                                        <Typography variant="body1" color="textPrimary" className="mr-1">¿No tienes cuenta aún?</Typography>
                                        <Link component={RouterLink} variant="body1" color="secondary" to={'/signup' + location.search} onClick={() => {
                                            eventData['source'] = 'login';
                                            eventTracker('cta_registro', eventData, ['mixpanel', 'hubspot', 'google']);
                                        }}>
                                            Registrate aquí
                                        </Link>
                                    </Box>
                                </>
                        }
                <AuthScreen onLoginError={onLoginError} handleLoader={onLoading} loading={loading} signupData={signupData} onOpenAutoSignup={onOpenAutoSignup} />
            </form>
        </Box>
    )
}

const mapStateToProps = ({ userReducer, profilePicReducer, tourInitialReducer }) => ({
    user: userReducer.user,
    profilePic: profilePicReducer.profilePic,
    showTour: tourInitialReducer.showTour
});

const mapDispatchToProps = { ...userActions, ...profilePicActions, ...tourInitialActions, ...menuActions };
export default connect(mapStateToProps, mapDispatchToProps)(SplitLogin);