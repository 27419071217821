import React from 'react';
import { Link as RouterLink} from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as paths from '../../../../routes/paths'


import { MEMBERSHIPS_STUDENTS_TEACHERS } from '../../../../shared/constants/memberships';
import { Box, Button, Card, CardContent, Typography } from '@material-ui/core';
import getImageFromAssets from '../../../../design-system/utils/getImageFromAssets';

// @material-ui/core
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    [theme.breakpoints.down('sm')]:{
        legend:{
            textAlign: 'center'
        }
    }
}));

const BannerNotCourses = () => {
    const classes = useStyles();
    const { membership_id } = useSelector((state) => state.userReducer.user);

    const Legend = () => {
        if(MEMBERSHIPS_STUDENTS_TEACHERS.includes(membership_id)){
            return(
                <>
                    <Typography variant='h3'>¡Te damos la bienvenida a un nuevo período escolar!</Typography>
                    <Typography>
                    Aquí encontrarás tus materias una vez que hayas <span className='text-blue-300 semi-bold'>creado tu horario y el período escolar haya iniciado.</span> Por ahora puedes explorar el catálogo de cursos de nuestros aliados e inscribirte a cualquiera de los +21,000 cursos a los que tienes acceso.
                    </Typography>
                </>
            )
        }

        return(
            <>
                <Typography variant='h3'>¿Listo para iniciar tus estudios?</Typography>
                <Typography>
                Aquí encontrarás tus cursos en progreso. <span className='text-blue-300 semi-bold'>Explora nuestro catálogo e inscríbete</span> al programa académico de tu preferencia o a cualquiera de los +21,000 cursos de educación continua a los que tienes acceso.
                </Typography>
            </>
        )

    }

    return (
        <Card variant='outlined' className='margin-t-3'>
            <CardContent>
                <Box display='flex' justifyContent='center' gridGap={32} alignItems='center' className='flex-sm-column'>
                <img src={getImageFromAssets('themeReading')} />
                <Box display='flex' gridGap={24} flexDirection='column' maxWidth={800}>
                    <Box display='flex' gridGap={16} flexDirection='column' className={classes.legend}>
                        <Legend />
                    </Box>
                    <Box display='flex' gridGap={24} className='flex-md-column'>
                        <Button variant='contained' color='primary' component={RouterLink} to={paths.ROOT_HOME} >Explorar catálogo</Button>
                        {/* <Button variant='outlined' color='secondary'>Reportar problema</Button> */}
                    </Box>
                </Box>
                    
                </Box>
            </CardContent>
        </Card>
    );
};

export default BannerNotCourses;