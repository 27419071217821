import React from 'react'

import { Checkbox, FormControlLabel, Grid, Icon, Typography, useMediaQuery, useTheme} from '@material-ui/core'

import { Link } from 'react-router-dom'

// paths
import * as paths from '../../../../../../routes/paths';

const PaymentsConditions = ({ acceptPayment, setAcceptPayment }) => {
    const theme = useTheme();
    // Detecta si la pantalla está en tamaño desktop según el breakpoint "md"
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    return (
        <Grid container>
            <Grid item xs={isDesktop ? 9: 12} className="pb-3">
                <FormControlLabel style={{ alignItems: "flex-start" }} checked={acceptPayment}
                    control={<Checkbox color="primary" size='small' onChange={() => setAcceptPayment(!acceptPayment)} />}
                    label={
                        <Typography variant='caption' className="semi-bold">
                            Estoy de acuerdo con los montos presentados y acepto los &nbsp;
                            <Link to={paths.TERMINOS_CONDICIONES} className='text-orange-300' target={'_blank'}>
                                Términos y Condiciones
                            </Link>
                        </Typography>
                    }
                />
            </Grid>
            <Grid item xs={12} className="pb-3">
                <Grid container spacing={1} alignItems='center'>
                    <Grid item>
                        <Icon className="ri-lock-2-line text-fill-stroke-100" style={{ fontSize: 'inherit', verticalAlign: 'middle' }} />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography variant='button' className='text-gray-300'>
                            Todos los pagos son seguros y encriptados.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className="pb-3">
                <Grid container spacing={1} alignItems='center'>
                    <Grid item>
                        <Icon className="ri-error-warning-line text-orange-300" style={{ fontSize: 'inherit', verticalAlign: 'middle' }} />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography variant='caption' className="semi-bold">
                            En caso de modificar el método de pago deberás reiniciar el proceso de compra.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PaymentsConditions;