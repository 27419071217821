import objectIsEmpty from '../../design-system/utils/objectIsEmpty';
import * as userTypes from '../types/userTypes'

const INITIAL_STATE = {
  user: {},
  userInterests: [],
  favInterests: [],
  userDetails: {},
  isLogged: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userTypes.APPLY_USER:
      return { ...state, 
        user: action.payload,
        isLogged: !objectIsEmpty(action.payload) 
      };
    
    case userTypes.GET_INTEREST:
      return {
        ...state,
        userInterests: action.payload
      }

    case userTypes.GET_FAV_INTEREST:
      return {
        ...state,
        favInterests: action.payload
      }
    case userTypes.SET_INTERESTS:
      return {
        ...state,
        user: {
          ...state.user,
          interests: action.payload
        }
      }
    case userTypes.GET_INTERESTS:
      return {
        ...state,
        user: {
          ...state.user,
          interests: action.payload
        }
      }
    case userTypes.UPDATE_MEMBERSHIP:
      return {
        ...state,
        user: {
          ...state.user,
          membership_id: action.payload
        }
      }
    case userTypes.APPLY_USER_DETAILS:
      return { ...state, userDetails: action.payload };
    default:
      return state;
  }
};
