import React from 'react';

// @material-ui/core components
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';

// core
import Sidebar from '../components/Sidebar/Sidebar'
import Navbar from '../components/Navbar/Navbar';
import Box from '@material-ui/core/Box';

// Share components
import SimulationBar from '../components/SimulationBar/SimulationBar';
import AlertTemsAndConditions from '../components/AlertTemsAndConditions/AlertTemsAndConditions'
import ProductsAssignments from '../../pages/Courses/ProductsAssignments/ProductsAssignments.jsx';


// styles
import useStyles from '../../assets/styles/jss/layout/mainStyle'
import clsx from 'clsx';
import Footer from '../components/Footer/Footer';
import SidebarMenu from './components/SidebarMenu';
import BottomMenu from '../components/BottomMenu/BottomMenu';
import * as sessions from '../../shared/utils/Sessions';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Routes

const Home = (props) => {
    const { children, hideNavLinks, isCheckout, isReferrals, layoutProps, hideFooter = false, hideNavBar = false, showLoader = false } = props;
    const [open, setOpen] = React.useState(false);
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const [maxWidthContainer, setMaxWidthContainer] = React.useState('lg');
    const [disableGutters, setDisableGutters] = React.useState(false);
    const userIsLogged = sessions.userIsLogged();
    const isLogged = userIsLogged;
    const classes = useStyles({ disableGutters, isLogged });
    const [state, setState] = React.useState({
        openHotSale: true
    })
    

    React.useEffect(()=>{
        if(layoutProps){
            setMaxWidthContainer(layoutProps.maxWidthContainer)
            setDisableGutters(layoutProps.disableGutters)
        }
    })

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleChangeState = (name, value) => {
        console.log('staue', name, value)
        setState({
            ...state,
            [name]: value
        });
    };

    return (
        <div className={classes.simulation_bar}>
            {
                userIsLogged && !showLoader &&
                <AlertTemsAndConditions />
            }
            <SimulationBar />
            <div className={classes.root}>
                <CssBaseline />
                {
                    !hideNavBar &&
                    <Navbar
                        isHome
                        isLogged={userIsLogged}
                        isCheckout={isCheckout}
                        isReferrals={isReferrals}
                        hideNavLinks={hideNavLinks}
                        showLoader={showLoader}
                        position="fixed" {...children.props}
                        className={clsx(classes.appBar, {[classes.appBarShift]: open})} 
                        handleDrawerToggle={handleDrawerOpen} 
                        onChangeState={handleChangeState}
                    />
                }
                <Sidebar open={open} onClose={handleDrawerClose} {...children.props} isLogged={userIsLogged}>
                    <SidebarMenu />
                </Sidebar>
                <main
                    className={clsx(classes.content, {
                    [classes.contentShift]: open,
                    })}
                >
                    {
                        !hideNavBar &&
                        <div id="drawerHeader" className={clsx(classes.drawerHeader, {'drawer-top': state.openHotSale && !isLogged})} />
                    }
                    <Container
                        maxWidth={maxWidthContainer}
                        disableGutters={disableGutters}
                    >
                        {children}
                    </Container>
                </main>
                {
                    userIsLogged && <BottomMenu />
                }
            </div>
            {
                ((!userIsLogged && isResponsive) || !isResponsive) &&
                <Footer hideFooter={hideFooter} isLogged={userIsLogged}/>
            }
            {
                userIsLogged && layoutProps?.showProductAssignmentModal && <ProductsAssignments/>
            }
        </div>
    );
};

export default Home;