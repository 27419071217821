import React from 'react';
import { connect } from "react-redux";

// @material-ui/core
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

// core components
import CoursesTaught from './CoursesTaught/CoursesTaught';
import CardsCoursesInProgress from "./CoursesInProgress/CardsCoursesInProgress";
import CoursesCompleted from './CoursesCompleted/CoursesCompleted';
import SavedCourses from './SavedCourses/SavedCourses';
import RecommendedCourseMyClass from './RecommendedCourses/RecommendedCourseMyClass';

import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { hasInstructorPermission } from '../../services/UserService';


import { log } from '../../shared/utils/console.js'

// Material Functions
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { eventTracker, eventData } from '../../shared/components/EventTracker/EventTracker';
import InformativeBanner from '../../design-system/components/Banners/InformativeBanner.jsx';
import WarningBanner from '../../design-system/components/Banners/WarningBanner.jsx';

// SDK
import { RecordDocumentService } from '@sdk-point/talisis';
import { Link, Typography } from '@material-ui/core';
import clsx from 'clsx';
import CoursesProvider from '../../shared/components/CourseCarousel/context/CoursesProvider.jsx';
import AddCurp from './components/AddCurp.jsx';

import { UserService } from '@sdk-point/talisis';
const RecordDocumentServiceSDK = new RecordDocumentService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const UserServiceSDK = new UserService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

let tabs = [
    { value: 'Mis Materias', uuid: uuid() },
    { value: 'En progreso', uuid: uuid() },
    { value: 'Guardados', uuid: uuid() },
    { value: 'Completados', uuid: uuid() },
    { value: 'Recomendados', uuid: uuid() },
];

const useStyles = makeStyles((theme) => ({
    tabMain: {
        overflow: "visible",
        borderBottom: "1px solid #828D9E",
        paddingLeft: "62px",
        '& .MuiTabs-flexContainer': {
            borderBottom: "none",
        },
        '& .MuiTabs-scroller': {
            maxWidth: "1378px",
            marginLeft: "24px",
            // marginRight: "auto",
        },
        "@media (max-width: 1250px)": {
            '& .MuiTabs-flexContainer': {
                minWidth: "max-content",
            }
        },
        "@media (max-width: 740px)": {
            paddingLeft: "15px",
            paddingRight: "15px",
        },
    },
    mainCenter: {
        width: "100%",
        boxSizing: "border-box",
        position: "absolute",
        left: "0px",
        // top: "101px",
        paddingLeft: "0px",
        paddingRight: "0px",
        overflow: "hidden",
        maxWidth: "100vw"
    },
    mainCarousels: {
        width: "100%",
        boxSizing: "border-box",
        position: "absolute",
        left: "0px",
        top: "185px",
        paddingLeft: "0px",
        paddingRight: "0px",
        overflow: "hidden",
        maxWidth: "100vw",
    },
    mainCarousels_2: {
        width: "100%",
        boxSizing: "border-box",
        marginTop: props => props.isResponsive ? '25%' : '8%',
        overflow: "hidden",
        maxWidth: "100vw",
        "@media (max-width: 425px)": {
            marginTop: '100px'
        },
    },
    tabs: {
        textTransform: "capitalize",
        [theme.breakpoints.down('xs')]:{
            fontSize: '15px'
        }
    },

}));

const Coursesv2 = ({ user }) => {
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const titleBanner = isResponsive 
        ? <span>¡Gracias por tu paciencia!<br/>Estamos experimentando problemas técnicos</span>
        : '¡Gracias por tu paciencia! Estamos experimentando problemas técnicos';

    const [chargeTarget, setChargeTarget] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [tabCatalogCoursesTaught, setTabCatalogCoursesTaught] = React.useState(false);
    const [tabCatalogAllCourses, setTabCatalogAllCourses] = React.useState(true);
    const [tabCoursesInProgress, setTabCoursesInProgress] = React.useState(false);
    const [tabCoursesCompleted, setTabCoursesCompleted] = React.useState(false);
    const [tabSavedCourses, setTabSavedCourses] = React.useState(false);
    const [hasRejectedDocuments, setHasRejectedDocuments] = React.useState(false);

    const history = useHistory();
    const classes = useStyles({isResponsive});
    const [personData, setPersonData] = React.useState({activateCurp: null})

    const instructorPermission = hasInstructorPermission();

    React.useEffect(() => {
        if(user.person_id){
            getUserData();
        }
    }, [user])

    const getUserData = async() => {
        try {
            const response = await UserServiceSDK.getData(user.person_id);
            if(response.user){
                setPersonData(response.user)
            }
        } catch (error) {
            
        }
    }
    
    // Se deshabilita el tab de "Mis Materias", cuando el usuario no es un Maestro
    if (!instructorPermission) {
        tabs = [
            { value: 'En progreso', uuid: uuid() },
            { value: 'Guardados', uuid: uuid() },
            { value: 'Completados', uuid: uuid() },
            { value: 'Recomendados', uuid: uuid() },
        ];
    }

    const handleChange = (event, newValue) => {
        event.preventDefault();
        if (instructorPermission) {
            if (newValue === 0) {
                handleChangeTabTaught(event)
            }
            else if (newValue === 1) {
                handleChangeTabInProgress(event);
            }
            else if (newValue === 2) {
                handleChangeTabSaved();
            }
            else if (newValue === 3) {
                handleChangeTabCompleted();
            }
            else if (newValue === 4) {
                handleChangeTabRecomendados();
            }
        }
        else {
            if (newValue === 0) {
                handleChangeTabInProgress(event);
            }
            else if (newValue === 1) {
                handleChangeTabSaved();
            }
            else if (newValue === 2) {
                handleChangeTabCompleted();
            }
            else if (newValue === 3) {
                handleChangeTabRecomendados();
            }
        }
        setValue(newValue);
    };

    const handleChangeTabTaught = (e) => {
        e.preventDefault();
        history.push('/cursos?target=mis-materias');
        setTabCatalogCoursesTaught(true);
        setValue(0);
    };

    const handleChangeTabInProgress = (e) => {
        eventTracker('s_clases_progreso', eventData, ['mixpanel']);
        e.preventDefault();
        history.push('/cursos?target=progreso');
        setTabCoursesInProgress(true);
        setValue(instructorPermission ? 1 : 0);
    };

    const handleChangeTabSaved = () => {
        eventTracker('s_clases_guardados', eventData, ['mixpanel']);
        history.push('/cursos?target=guardados');
        setTabSavedCourses(true);
        setValue(instructorPermission ? 2 : 1);
    };

    const handleChangeTabCompleted = () => {
        eventTracker('s_clases_completados', eventData, ['mixpanel']);
        history.push('/cursos?target=completados');
        setTabCoursesCompleted(true);
        setValue(instructorPermission ? 3 : 2);
    };

    const handleChangeTabRecomendados = () => {
        eventTracker('s_clases_recomendados', eventData, ['mixpanel']);
        history.push('/cursos?target=recomendados');
        setTabCatalogAllCourses(true);
        setValue(instructorPermission ? 4 : 3);
    };

    const handleClickRedirectBanner = () => {
        history.push("/profile?tab=2");
    };

    const getData = async () => {
        try {
            const queryParams = new URLSearchParams(window.location.search);
            const target = await queryParams.get('target');
            return target;
        }
        catch (e) {
            log(e.message);
        }
    };

    React.useEffect(() => {
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        
        let isCanceled = true;

        const validateRejectedDocument = async () => {
            try {
                const response = await RecordDocumentServiceSDK.getDocumentsByStatus({personId: user.person_id, status: [3, 6]});

                if (!!isCanceled && !!response.success) {
                    setHasRejectedDocuments(!!response.data.documents.length);
                }
            } catch (error) {
            }
        };

        getData()
            .then((target) => {
                if (isCanceled) {
                    if (instructorPermission) {
                        if (target === 'mis-materias') {
                            setTabCatalogCoursesTaught(true);
                            setValue(0);
                        }
                        else if (target === "progreso") {
                            setTabCoursesInProgress(true);
                            setValue(1);
                        }
                        else if (target === "guardados") {
                            setTabSavedCourses(true);
                            setValue(2);
                        }
                        else if (target === "completados") {
                            setTabCoursesCompleted(true);
                            setValue(3);
                        }
                        else if (target === "recomendados") {
                            setTabCatalogAllCourses(true);
                            setValue(4);
                        }
                        else {
                            setTabCatalogCoursesTaught(true);
                            setValue(0);
                        }
                    }
                    else {
                        if (target === "progreso") {
                            setTabCoursesInProgress(true);
                            setValue(0);
                        }
                        else if (target === "guardados") {
                            setTabSavedCourses(true);
                            setValue(1);
                        }
                        else if (target === "completados") {
                            setTabCoursesCompleted(true);
                            setValue(2);
                        }
                        else if (target === "recomendados") {
                            setTabCatalogAllCourses(true);
                            setValue(3);
                        }
                        else {
                            setTabCoursesInProgress(true);
                            setValue(0);
                        }
                    }
                    setChargeTarget(true);
                }
            })
            .catch((err) => {
                log(`Failed: ${err}`);
            });

        validateRejectedDocument();
        return () => (isCanceled = false);
    }, []);

    const hanldeUpdateCurpSuccessful = () => {
        setPersonData({
            ...personData,
            activateCurp: false
        })
    }

    return (
        <>
        <CoursesProvider>
            <AddCurp person={personData} updateCurpSuccessful={hanldeUpdateCurpSuccessful}/>
            {/* <InformativeBanner
                image='themeCodigo' 
                title={titleBanner}
                text='Es posible que algunas de tus materias terminadas aún aparezcan como en progreso. No te preocupes, ¡estamos en ello! Nuestro equipo ya está trabajando para solucionar este problema. No necesitas hacer ningún reporte, ¡todo estará en orden muy pronto!'
            />  */}
            {
                !!hasRejectedDocuments && 
                <WarningBanner
                    title='Tienes uno o más documentos rechazados'
                    text={
                        <Typography variant='body2'>
                            Lamentamos informarte que cuentas con uno o más documentos que han sido rechazados por nuestro equipo de Escolar. Para continuar con tu proceso de inscripción, por favor vuelve a cargar los documentos corregidos en nuestro sistema,&nbsp;
                            <Link underline="always" color='secondary' component="button" variant="body2"
                                onClick={handleClickRedirectBanner}
                            >
                                Haciendo clic aquí.
                            </Link>
                        </Typography>
                    }
                    />
            }
            <Box width="100%" style={{ overflowX: "hidden" }}>
                <Box className={classes.mainCenter}>
                    <Tabs className={classes.tabMain} variant="scrollable" scrollButtons="auto" value={value} onChange={handleChange} aria-label="scrollable auto tabs example" indicatorColor="primary">
                        {
                            tabs.map((tab, index) => <Tab className={classes.tabs} key={tab.uuid} label={tab.value} {...a11yProps(index)} />)
                        }
                    </Tabs>
                </Box>
                <Box className={classes.mainCarousels_2}>
                    


                    {instructorPermission ?
                        <React.Fragment>
                            {tabCatalogCoursesTaught == true && value === 0 && <Box>
                                <CoursesTaught />
                            </Box>}
                            {tabCoursesInProgress == true && value === 1 && <Box>
                                <CardsCoursesInProgress />
                            </Box>}
                            {tabSavedCourses === true && value === 2 && <Box>
                                <SavedCourses isMyCourses={true} />
                            </Box>}
                            {tabCoursesCompleted === true && value === 3 && <Box>
                                <CoursesCompleted />
                            </Box>}
                            {tabCatalogAllCourses === true && value === 4 && <Box>
                                <RecommendedCourseMyClass handleChangeTabSaved={handleChangeTabSaved} handleChangeTabInProgress={handleChangeTabInProgress} />
                            </Box>
                            }
                        </React.Fragment>
                        :
                        <React.Fragment>
                            {tabCoursesInProgress == true && value === 0 && <Box>
                                <CardsCoursesInProgress />
                            </Box>}
                            {tabSavedCourses === true && value === 1 && <Box>
                                <SavedCourses isMyCourses={true} />
                            </Box>}
                            {tabCoursesCompleted === true && value === 2 && <Box>
                                <CoursesCompleted />
                            </Box>}
                            {tabCatalogAllCourses === true && value === 3 && <Box>
                                <RecommendedCourseMyClass handleChangeTabSaved={handleChangeTabSaved} handleChangeTabInProgress={handleChangeTabInProgress} />
                            </Box>
                            }
                        </React.Fragment>}
                </Box>
            </Box>
        </CoursesProvider>
        </>
    );
};

const mapStateToProps = (reducers) => reducers.userReducer;
export default connect(mapStateToProps, {})(Coursesv2);