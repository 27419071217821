import palette from '../palette';

export default {
    root: {
        backgroundColor: palette.colors.fillStroke[400],
        borderRadius: 8,
        boxShadow: 'unset',
        '&.MuiPaper-outlined':{
            backgroundColor: palette.colors.fillStroke[400],
            border: `1px solid ${palette.colors.fillStroke[200]}`
        },
        '&.MuiPaper-elevation0':{
            backgroundColor: 'transparent',
        },
        '&.MuiPaper-elevation2':{
            backgroundColor: palette.colors.fillStroke[300],
            border: `.5px solid ${palette.colors.fillStroke[100]}`
        },
        '&.MuiPaper-elevation3':{
            background: 'rgba(159, 111, 251, 0.16) !important',
            border: `0.5px solid ${palette.colors.violet[300]} !important`
        },
        '&.MuiPaper-elevation4':{
            backgroundColor: palette.colors.fillStroke[400],
            border: `.5px solid ${palette.colors.fillStroke[100]}`
        },
        '&.MuiPaper-elevation5':{
            backgroundColor: palette.colors.fillStroke[500],
            border: `.5px solid ${palette.colors.fillStroke[200]}`
        },
        '&.MuiPaper-elevation6':{
            backgroundColor: palette.colors.fillStroke[300],
        },
        '&.MuiPaper-elevation7':{
            backgroundColor: palette.colors.fillStroke[400],
            borderBottom: `1px solid ${palette.colors.fillStroke[200]}`,
            borderLeft: `6px solid ${palette.colors.fillStroke[200]}`
        },
        '&.MuiPaper-elevation8':{
            backgroundColor: palette.colors.fillStroke[500],
            border: `0.5px solid ${palette.colors.warning[300]}`
        },
        '&.MuiPaper-elevation9':{
            backgroundColor: palette.colors.fillStroke[400],
            border: `1px solid ${palette.colors.violet[300]} !important`
        },
        '&.primary-hover':{
            cursor: 'pointer',
            '&:hover':{
                backgroundColor: palette.colors.violet[300],
            }
        },
        '& .MuiCardContent-root:last-child':{
            paddingBottom: 16
        }
    },
};
