import React from 'react';
import { useParams } from 'react-router-dom';

// redux
import { connect } from 'react-redux';

//@material-ui
import { Box, Grid, Card, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Select from '../../../design-system/components/Form/Select/Select';
import { SIMPLE_DATE_FORMAT } from '../../../design-system/constants/date-formats';

import CardFirst from '../../../shared/components/Card/CardTwoParts/CardFirst';
import Breadcrumb from '../../../shared/components/Breadcrumb/Breadcrumb';
import dateFormat from '../../../design-system/utils/dateFormat';
import LoaderContent from '../../../shared/components/Loader/LoaderContent';

// Constants
import { APPROVED_STATUS, PENDING_STATUS, REJECTED_STATUS, REVIEW_STATUS } from '../../../shared/constants/record-status';

import NewDocumentButton from '../components/NewDocumentButton';
import InstructionsTitle from '../components/InstructionsTitle';
import ListDocuments from '../components/ListDocuments';
import FileDetail from '../components/FileDetail';
import ReviewConfirmBttn from '../components/ReviewConfirmBttn';

import clsx from 'clsx';
import { v4 as uuid } from 'uuid';

// SDK
import { AccountService, RecordDocumentService } from '@sdk-point/talisis';
const AccountServiceSDK = new AccountService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const RecordDocumentServiceSDK = new RecordDocumentService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => ({
    listItem: {
        borderBottom: `0.5px solid ${theme.palette.colors.fillStroke[200]}`
    },
    comments: {
        '& .MuiIcon-root': {
            fontSize: '24px !important'
        }
    },
    dialogTitle: {
        display: 'flex',
        paddingBottom: 0,
        alignItems: 'center',
        '& .MuiTypography-root': {
            flex: 1,
            textAlign: 'center'
        }
    },
    breadcrumb:{
        paddingLeft:48
    },
    selectStatus:{
        width: 280,
        textAlign: 'end'
    },
    [theme.breakpoints.down('sm')]:{
        breadcrumb:{
            paddingLeft:0,
            marginBottom: 28
        },
        groupsGrid:{
            order: 2
        },
    },
    [theme.breakpoints.down('xs')]:{
        root:{
            padding: '28px 0 90px'
        },
        groupListItem:{
            padding: 16,
            flexDirection: 'column',
            '&.inline':{
                flexDirection: 'row',
            }
        },
        selectStatus:{
            padding: '16px 0px 0px 42px'
        }, 
    }
}));

const DocumentReceptionDetail = ({user}) => {
    const classes = useStyles();
    const { id } = useParams();

    const [profileData, setProfileData] = React.useState(null);
    const [docuemntsList, setDocuemntsList] = React.useState([]);
    const [additionalDocuemntsList, setAdditionalDocuemntsList] = React.useState([]);
    const [personId, setPersonId] = React.useState(0);
    const [listType, setListType] = React.useState(0);
    const [studentArea, setStudentArea] = React.useState(0);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        getDocuments();
        getAdditionalDocuments();
    }, []);

    React.useEffect(() => {
        getProfile();
    }, [personId]);

    const getProfile = async () => {
        try {
            const profileDataResponse = await AccountServiceSDK.getProfileData(personId);
            profileDataResponse.fullName = `${profileDataResponse.first_name} ${profileDataResponse.last_name}`
            setProfileData(profileDataResponse);
        }
        catch (error) {
            console.log('error', error);
        }
    }

    const getDocuments = async () => {
        try {
            const dataResponse = await RecordDocumentServiceSDK.getDocumentsForAdmin(id);

            if (!!dataResponse.success) {
                setPersonId(dataResponse.data.personId);
                setListType(dataResponse.data.listType);
                setStudentArea(dataResponse.data.studentArea);
                for (const g of dataResponse.data.groups) {
                    for (const doc of g.documents) {
                        doc.newStatus = '';
                        doc.rejectedComments = '';
                        doc.currentDate = !!doc.showDate ? dateFormat(new Date(doc.showDate), SIMPLE_DATE_FORMAT) : null;
                    }
                }

                setDocuemntsList(dataResponse.data.groups);
            }

            setLoading(false)
        }
        catch (error) {
            console.log('error', error);
        }
    }

    const getAdditionalDocuments = async () => {
        try {
            const dataResponse = await RecordDocumentServiceSDK.getAdditionalDocumentForUser(id);

            if (!!dataResponse.success) {
                const tempData = dataResponse.data.map(item => ({...item, label: item.name}));
                setAdditionalDocuemntsList(tempData);
            }
        }
        catch (error) {
            console.log('error', error);
        }
    }

    const updateStatus = async (document, groupIndex, documentIndex) => {
        setLoading(true);
        try {
            const body = {
                record_status_id: parseInt(document.newStatus),
                approver_person_id: user?.person_id,
                error_message: document.newStatus === APPROVED_STATUS ? null : document.rejectedComments
            };
            const updateData = await RecordDocumentServiceSDK.updateRecord(document.record_id, {record: body, templateId: id});

            if (!!updateData.success) {
                setDocuemntsList(prev => {
                    const current = [...prev];
    
                    const newGroup = document.newStatus === REJECTED_STATUS ? PENDING_STATUS : APPROVED_STATUS;
                    const indexNewGroup = current.findIndex(it => newGroup === it.record_status_id[0]);
    
                    document.record_status_id = newGroup;
                    document.newStatus = "";
                    
                    switch(newGroup) {
                        case PENDING_STATUS || newGroup === REJECTED_STATUS:
                            document.currentDate = !!document.deadline ? dateFormat(new Date(document.deadline), SIMPLE_DATE_FORMAT) : null;
                            break;
                        case APPROVED_STATUS:
                            document.currentDate = !!document.last_updated ? dateFormat(new Date(document.last_updated), SIMPLE_DATE_FORMAT) : null;
                            break;
                    }

                    current[indexNewGroup].documents.push(document);
    
                    current[0].documents.splice(documentIndex, 1);
                    return current;
                });
            }

            setLoading(false)
        }
        catch (error) {
            console.log('error', error);
        }
    }

    const handleChangeDocValues = (groupIndex, documentIndex) => (e) => {
        const { name, value } = e.target;

        setDocuemntsList(prev => {
            const current = [...prev];
            current[groupIndex].documents[documentIndex][name] = value;
            return current;
        })
    }

    const handleClickAddDocument = async (newDocumentId) => {
        setLoading(true)

        try {
            const body = {
                typeId: listType,
                templateId: id, 
                documentId: newDocumentId
            }
            
            const createDocumentReponse = await RecordDocumentServiceSDK.createAdditionalDocumentToUser(body);

            if(!createDocumentReponse.success) {
                console.log('error createDocumentReponse');
            } else {
                getAdditionalDocuments();
                getDocuments();
            }
        }
        catch (error) {
            console.log('error', error);
        }
    }

    return (
        <Box className={classes.root} id="document-reception-detail-root">
            <Breadcrumb items={[{label: 'Alumnos'}, {label: 'Nuevo Ingreso', to: '/recepcion-documentos'}, {label: `Alumno #${id}`}]} className={classes.breadcrumb}/>

            <Hidden smDown>
                <InstructionsTitle
                    mb={3} mt="41px"
                    plain
                    title='Validación de documentos' 
                    subtitle='Revisa los siguientes documentos digitales y apruébalos o recházalos según sea el caso.'
                />   
            </Hidden>

            <Grid container spacing={6}>
                <Grid item xs={12} md={8} className={classes.groupsGrid}>
                    <LoaderContent loading={loading}>
                        <Hidden mdUp>
                            <InstructionsTitle 
                                divider
                                title='Validación de documentos' 
                                subtitle='Revisa los siguientes documentos digitales y apruébalos o recházalos según sea el caso.'
                            />                        
                        </Hidden>
                        {
                            docuemntsList.map((listGroup, index) =>
                                <ListDocuments 
                                    title={listGroup.label} 
                                    subtitle={`${listGroup.documents.length} Documentos`} 
                                    key={`list-documents-${index}`} open={!index} 
                                    status={listGroup.record_status_id[0]}
                                    noData={!listGroup.documents.length}
                                >
                                    {
                                        listGroup.documents.map((documentObj, documentIndex) =>
                                            <Box className={classes.listItem} key={`list-documents-item-${documentIndex}`}>
                                                <Box p={3} display='flex' alignItems='center' justifyContent='space-between' className={clsx(classes.groupListItem, {'inline': listGroup.record_status_id[0] !== REVIEW_STATUS})}>
                                                    <FileDetail 
                                                        key={uuid()}
                                                        title={documentObj.document_name}
                                                        fileName={documentObj.file_name}
                                                        filePath={documentObj.path}
                                                        dueDate={documentObj.currentDate}
                                                        active={listGroup.record_status_id[0] !== PENDING_STATUS}
                                                        flex={1}
                                                        status={listGroup.record_status_id[0] || null}
                                                        tags={[documentObj.program_name]}
                                                        instruction={documentObj.instruction}
                                                    />
                                                    {
                                                        listGroup.record_status_id[0] === REVIEW_STATUS &&
                                                        <Box className={classes.selectStatus}>
                                                            <Select 
                                                                value={documentObj.newStatus}
                                                                name="newStatus"
                                                                placeholder="Seleccionar estatus"
                                                                required
                                                                items={[
                                                                    { id: APPROVED_STATUS, label: "Aprobado" },
                                                                    { id: REJECTED_STATUS, label: "Rechazado" }
                                                                ]}
                                                                onChange={handleChangeDocValues(index, documentIndex)}
                                                            />
                                                        </Box>
                                                    }
                                                </Box>
                                                {
                                                    documentObj.newStatus && 
                                                    <ReviewConfirmBttn
                                                        newStatus={documentObj.newStatus} 
                                                        rejectedComments={documentObj.rejectedComments}
                                                        onChangeDocValues={handleChangeDocValues(index, documentIndex)}
                                                        onClickConfirm={() => updateStatus(documentObj, index, documentIndex)}
                                                    />
                                                }
                                            </Box>
                                        )
                                    }
                                </ListDocuments>
                            )
                        }
                    </LoaderContent>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card elevation={5} component={Box} p="32px 16px 16px">
                        <CardFirst email={profileData?.email} name={profileData?.fullName}>
                            <Box display="flex" gridGap="16px" flexDirection="column">
                                { !!studentArea &&
                                    <Box display="flex">
                                        <Typography variant='body2' className='text-dark-black-200'>Área del alumno:&nbsp;</Typography>
                                        <Typography variant='body2' className='semi-bold'>{studentArea}</Typography>
                                    </Box>
                                }
                                <NewDocumentButton listDocument={additionalDocuemntsList} onClick={handleClickAddDocument} />
                            </Box>
                        </CardFirst>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

const mapStateToProps = ({ userReducer }) => ({
    user: userReducer.user,
});

export default connect(mapStateToProps, null)(DocumentReceptionDetail);