import { Box, Button, Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import { SOURCES } from '../../../../../../design-system/constants/sources';
import { MEMBERSHIPS } from '../../../../../../design-system/constants/memberships';

const tutorialLinks = {
    [SOURCES.LINKEDIN_LEARNING]: {
        content: (
            <>
                <Typography variant='body2'>
                    Para acceder a los cursos de LinkedIn Learning necesitas vincular tu cuenta de <span className='semi-bold text-blue-300'>LinkedIn personal.</span>
                </Typography>
                <Typography variant='body2'>
                    Revisa el siguiente tutorial para ver el paso a paso de cómo realizar la vinculación.
                </Typography>
            </>
        ),
        tutorialUrl: 'activar-linkedin-learning.pdf'
    },
    [SOURCES.CREHANA]: {
        content: (
            <Typography variant='body2'>
                Revisa el siguiente tutorial si tienes dudas de cómo ingresar a Crehana.
            </Typography>
        ),
        tutorialUrl: 'activar-crehana.pdf'
    }
};

const AccessInstructions = ({ sourceId, membership }) => {
    const url = "https://digitalmedia.talisis.com/talisis/tutoriales";

    if (![SOURCES.CREHANA, SOURCES.LINKEDIN_LEARNING].includes(sourceId) || membership === MEMBERSHIPS.FREEMIUM) {
        return null;
    }

    const sourceInfo = tutorialLinks[sourceId];

    return (
        <Card className='mt-3' elevation={9} id="root-access-instructions">
            <CardContent style={{ position: 'relative', padding: 23 }}>
                <Box display='flex' flexDirection='column' gridGap={16}>
                    <Typography variant='h4'>Instrucciones de acceso a curso</Typography>
                    {sourceInfo?.content}
                    <Button 
                        variant='outlined' 
                        color='secondary' 
                        href={`${url}/${sourceInfo?.tutorialUrl}`} 
                        fullWidth 
                        target='_blank'
                    >
                        Ver tutorial
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};

export default AccessInstructions;
