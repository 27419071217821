import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';

// @material-ui/core
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import DescriptionIcon from '@material-ui/icons/Description';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';

// component-styles
import './horarioStyles.css'

import Clase from './Clase';
import ResponsiveClase from './ResponsiveClase';
import DynamicTable from './DynamicTable';
import { CircularProgress, Hidden, makeStyles } from '@material-ui/core';
import moment from 'moment';
import LogoOu from '../LogoOu/LogoOu';
import Asincronicos from './Asincronicos/Asincronicos';
import PrintPDFBtn from '../Academic/PrintPDFBtn/PrintPDFBtn';

import { AccountService } from '@sdk-point/talisis';
import { ArrowLeft, ArrowRight, Print } from '@material-ui/icons';
import NothingFound from '../../../../shared/components/NothingFound/NothingFound';
import { eventTracker } from '../../../../shared/components/EventTracker/EventTracker';
import LoaderContent from '../../../../design-system/components/Loader/LoaderContent';
const AccountServiceSDK = new AccountService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

// Table Headers
const semana = ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sábado"];
const colNames = ["Período", "Modalidad", "Clave Materia", "Grupo", "Abreviación", "Materia", "Profesor (a)"];
const colNames2 = ["Hora", "Día"];
const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
const CAMPUS_ONLINE = ["ONL", "CAV" ,"V"];

const list = [
    {
        hora: <div>Hora Inicial - Hora Final</div>,
        dia:
            <>
                <div>Período de inscripción</div>
                <div>Materia Abreviada</div>
                <div>Sálon / Grupo</div>
            </>
    }
]

const useStyles = makeStyles((theme) => ({
    rootHorario: {
        flexGrow: 1,
    },
    horarioContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    paperHorario: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        flex: '1 0 auto',
        margin: theme.spacing(1),
    },
}));

const Horario = (props) => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(true);

    const { about_me: { userName, user_id, ou }, studentProgram, service, isCurrentTab } = props;

    const { student_id } = useSelector((state) => state.userReducer.user);

    const printAreaHorario = useRef(null);

    const [fetchLoading, setFetchLoading] = useState(true);

    const [schedule, setSchedule] = useState([]);
    const scheduleData = [];

    const [horarios, setHorarios] = useState([]);
    const horariosData = [];

    let minStartDate = '';
    let minStartDates = [];
    let maxEndDate = '';
    let maxEndDates = [];

    const [periods, setPeriods] = useState([]);
    const periodsData = [];

    let weekPeriods = [];
    let weekPeriodName = '';
    const [currentWeekPeriod, setCurrentWeekPeriod] = useState(0);

    let currentPeriod = '';
    let currentPeriodName = '';
    if (periods.length > 0) {
        periods.forEach(period => {
            currentPeriod = Object.keys(period)[0];
            currentPeriodName = period[currentPeriod];
        });
    }
    
    let hours = {};
    horarios.filter(h => h.periodo === currentPeriod).map((h, k) => {
        if (h.horarios) {
            if (h.horarios.edges) {
                h.horarios.edges.map(edge => {
                    const node = edge.node;
                    if (node.inicio && !hours[node.inicio]) {
                        hours[node.inicio] = [];
                    }
                    minStartDates.push(new Date(node.fechaInicio));
                    maxEndDates.push(new Date(node.fechaFin));
                });
            }
        }
    });
    minStartDate = new Date(Math.min(...minStartDates));
    maxEndDate = new Date(Math.max(...maxEndDates));

    const classSchedule = [];
    horarios.filter(h => h.periodo === currentPeriod).map((h, k) => {
        if (h.horarios) {
            if (h.horarios.edges) {
                h.horarios.edges.map(edge => {
                    const node = edge.node;
                    const inicio = node.inicio ? moment(node.inicio, 'hh:mm').format('hh:mm A') : '';
                    const fin = node.fin ? moment(node.fin, 'hh:mm').format('hh:mm A') : '';
                    const dia = [];
                    if (node.lunes) dia.push('lunes');
                    if (node.martes) dia.push('martes');
                    if (node.miercoles) dia.push('miercoles');
                    if (node.jueves) dia.push('jueves');
                    if (node.viernes) dia.push('viernes');
                    if (node.sabado) dia.push('sabado');
                    if (node.domingo) dia.push('domingo');
                    if (hours[node.inicio]) {
                        hours[node.inicio].push({
                            ...h,
                            inicio: node.inicio,
                            horario: `${inicio} ${fin}`,
                            salon: node.salon,
                            dia: dia,
                            fechaInicio: node.fechaInicio.substring(0, 10),
                            fechaFin: node.fechaFin.substring(0, 10),
                        });
                    }
                });
            }
        }
    });

    const sortHours = Object.keys(hours).sort();
    sortHours.map(sh => {
        if (studentProgram && !!studentProgram && !(studentProgram.map(program => program.campus_id)).some(item => CAMPUS_ONLINE.includes(item))) {
            classSchedule.push({
                horario: hours[sh][0]['horario'],
                horaInicio: sh,
                materias: hours[sh]
            });
        }
    });

    const buildWeekFromDate = (startDate) => {
        const result = [];
        const sunday = new Date(startDate.setDate(startDate.getDate() - startDate.getDay()));
        while (sunday.setDate(sunday.getDate() + 1) && sunday.getDay() !== 0) {
            result.push(new Date(sunday));
        }

        return result;
    }

    const buildWeekNavigation = (startDate, endDate) => {
        const list = [];
        let i = 1;

        const padLeft = (number) => ('00' + number).slice(-2);

        let week = buildWeekFromDate(startDate);
        if (week.length > 0) {
            let weekLabel = `Semana del ${padLeft(new Date(week[0]).getDate())} de ${monthNames[new Date(week[0]).getMonth()]} al ${padLeft(new Date(week[5]).getDate())} de ${monthNames[new Date(week[5]).getMonth()]} del ${new Date(week[5]).getFullYear()}`;
            list.push({ [weekLabel]: week });

            while (week[5].getTime() < endDate.getTime()) {
                let saturday = new Date(week[5]);
                let nextMonday = saturday.setDate(saturday.getDate() + 2);

                i = i + 1;
                week = buildWeekFromDate(new Date(nextMonday));
                weekLabel = weekLabel = `Semana del ${padLeft(new Date(week[0]).getDate())} de ${monthNames[new Date(week[0]).getMonth()]} al ${padLeft(new Date(week[5]).getDate())} de ${monthNames[new Date(week[5]).getMonth()]} del ${new Date(week[5]).getFullYear()}`;
                list.push({ [weekLabel]: week });
            }
        }

        return list;
    }
    weekPeriods = buildWeekNavigation(minStartDate, maxEndDate);
    weekPeriodName = weekPeriods[currentWeekPeriod] ? Object.keys(weekPeriods[currentWeekPeriod])[0] : '';

    const changeWeekPeriod = (period, direction) => {
        if (weekPeriods[period]) {
            if (direction === 'left') {
                weekPeriodName = Object.keys(weekPeriods[period])[0];
                setCurrentWeekPeriod(period >= 1 ? period - 1 : 0);
            }
            else {
                weekPeriodName = Object.keys(weekPeriods[period])[0];
                setCurrentWeekPeriod(weekPeriods.length - 1 === period ? period : period + 1);
            }
        }
    };

    const sendEscolarHorarioEventToMixPanel=()=>{
        const eventData = {};
        eventTracker('s_escolar_horario', eventData, ['mixpanel']);
    };    

    React.useEffect(()=>{
        if(isCurrentTab)
        {
            sendEscolarHorarioEventToMixPanel();
        }
    },[isCurrentTab]);

    React.useEffect(() => {
        const fetchData = async () => {
            const result = await AccountServiceSDK.getStudentScheduleCalifications(ou, user_id, 'schedule');
            setLoading(false);

            if (!student_id) return;

            if (result.data) {
                if (result.data.persona && result.data.persona.boletas && result.data.persona.boletas.edges) {
                    result.data.persona.boletas.edges.map(edge => {
                        if (edge.node) {
                            if (edge.node.materias) {
                                if (edge.node.materias.edges) {
                                    const periodo = edge.node.clave;
                                    const nombrePeriodo = edge.node.periodo.nombre;
                                    periodsData.push({
                                        [parseInt(periodo)]: nombrePeriodo
                                    });

                                    edge.node.materias.edges.map(edge => {
                                        horariosData.push({
                                            periodo: periodo,
                                            materia: edge.node.titulo,
                                            grupo: edge.node.grupo,
                                            horarios: edge.node.horarios,
                                        });
                                        scheduleData.push({
                                            periodo: periodo,
                                            modalidad: "Presencial",
                                            clave: edge.node.materia,
                                            grupo: edge.node.grupo,
                                            abreviacion: edge.node.titulo,
                                            materia: edge.node.tituloLargo,
                                            profesor: edge.node.docente,
                                        });
                                    });

                                    setFetchLoading(false);
                                }
                            }
                        }
                    });
                }
                setPeriods(periodsData);
                setSchedule(scheduleData);
                setHorarios(horariosData);
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ou, service, user_id]);

    const getSalones = (horarios) => {
        const salones = horarios.map(clase =>
            clase.horarios?.edges[0]?.node?.salon
        )
        return salones;
    };

    const replaceModalidad = () => {
        const filteredSalones = getSalones(horarios);
        const checkModalidadArray = (!!studentProgram && !(studentProgram.map(program => program.campus_id)).some(item => CAMPUS_ONLINE.includes(item))) ?
            schedule.map((clase, index) => {
                if (filteredSalones[index] !== null) {
                    return {
                        ...clase,
                        modalidad: 'Presencial'
                    }
                }
                else {
                    return {
                        ...clase,
                        modalidad: 'Online'
                    }
                }
            })
            : [];

        return checkModalidadArray;
    }

    const scheduleWithModalidad = replaceModalidad();
    const hasSchedule = !fetchLoading && classSchedule.length !== 0;
    const isAsincrono = student_id && classSchedule.length > 0;

    return (
        <>
            <Grid item md={12}>
                <Card ref={printAreaHorario}>
                <LoaderContent loading={loading}>
                    <CardContent>
                        <Grid className='align-info'>
                            <LogoOu />
                            <SignalCellularAltIcon fontSize='inherit' color="secondary" />
                            <Typography color="secondary" variant='caption' className='mr-2'>-</Typography>
                            <DescriptionIcon fontSize='inherit' color="secondary" />
                            <Typography color="secondary" variant='caption' className='mr-2'>{currentPeriodName}</Typography>
                        </Grid>
                        <Typography className='m-1' variant="body1">Consulta de horario</Typography>
                        <Typography className='ml-1' color="textSecondary" variant="body1">
                            Alumno: {`${user_id ? user_id : ""} ${userName}`}
                        </Typography>
                        <Typography className='ml-1' color="textSecondary" variant="body1">
                            Carrera: {
                                studentProgram &&
                                studentProgram.map(program => `${program.description[0]} (${program.program_id} ${program.major_id})`)
                            }
                        </Typography>
                        <Grid className='align-info'>
                            <Typography className='ml-1' color="textSecondary" variant="body1">
                                Nivel: {
                                    studentProgram &&
                                    studentProgram.map(program => program.level_id)
                                }
                            </Typography>
                            <Typography className='ml-5' color="textSecondary" variant="body1">
                                Campus: {
                                    studentProgram &&
                                    studentProgram.map(program => program.campus_id)
                                }
                            </Typography>
                        </Grid>
                        <br />
                        {
                            student_id && classSchedule.length > 0 &&
                            <Grid container className='align-elements mb-2'>
                                <Card variant='outlined' className='period-container'>
                                    <Button onClick={() => { changeWeekPeriod(currentWeekPeriod, 'left') }}><ArrowLeft /></Button>
                                    <Typography component="span" className="period-label">{weekPeriodName
                                        ? weekPeriodName
                                        : <CircularProgress style={{ height: '12px', marginTop: '5px', width: '12px' }} color="primary" />}
                                    </Typography>
                                    <Button onClick={() => { changeWeekPeriod(currentWeekPeriod, 'right') }}><ArrowRight /></Button>
                                </Card>
                                {
                                    isAsincrono &&
                                    <Grid container justifyContent='flex-end' className='period-btn'>
                                        <PrintPDFBtn btnName={"Imprimir Horario"} elementId={printAreaHorario.current}
                                            btnDisabled={!hasSchedule}
                                            icon={<Print />}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        }
                        {
                            classSchedule.length > 0 &&
                            <div className={classes.rootHorario}>
                                <div className={classes.horarioContainer}>
                                    <Hidden key="desktop" mdDown>
                                        <Grid key="desktop-grid" className='weekdays-wrapper mt-3'>
                                            {
                                                semana.map((dia, id) =>
                                                    <Typography key={dia} color="default" className='weekday-chip'>
                                                        <span>{dia}</span>
                                                    </Typography>
                                                )
                                            }
                                        </Grid>
                                        {classSchedule.map((cs, k) => <Clase key={k} horaInicio={cs.horaInicio} horario={cs.horario} materias={cs.materias} weekPeriod={weekPeriods[currentWeekPeriod]} />)}
                                    </Hidden>
                                    <Hidden key="responsive" lgUp>
                                        <Grid key="responsive-grid" container spacing={2}>
                                            {
                                                semana.map((dia, id) => {
                                                    return (
                                                        <React.Fragment key={dia}>
                                                            <Grid item xs={12}>
                                                                <Typography key={dia} className='responsive-weekday-chip'>
                                                                    <span>{dia}</span>
                                                                </Typography>
                                                            </Grid>
                                                            {classSchedule.map((cs, k) =>
                                                                cs.materias.map((materia, k) => {
                                                                    const _dia = dia.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                                                                    if (materia.dia.includes(_dia)) {
                                                                        return <ResponsiveClase key={k} horario={materia.horario} materia={[materia]} dia={_dia} weekPeriod={weekPeriods[currentWeekPeriod]} />
                                                                    }
                                                                })
                                                            )}
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Hidden>
                                </div>
                            </div>
                        }
                        {
                            (!student_id) &&
                            <NothingFound
                                subcaption={"No estás inscrito a ningún plan de estudios actualmente"}
                            />
                        }
                        {(student_id && !hasSchedule && !fetchLoading) && <Asincronicos />}
                        {
                            (fetchLoading && hasSchedule) &&
                            <div style={{ textAlign: 'center', padding: '16px', verticalAlign: 'middle' }}>
                                <CircularProgress style={{ height: '24px', marginTop: '5px', width: '24px' }} color="primary" />
                            </div>
                        }
                        {
                            !classSchedule.length && !loading &&
                            <NothingFound
                                caption={""}
                                subcaption={"Por lo pronto no cuentas con información para mostrar en esta sección"}
                            />
                        }
                    </CardContent>
                </LoaderContent>
                </Card>
                {
                    student_id && scheduleWithModalidad.length !== 0
                    && <>
                        <Typography className='mt-3' variant="body2">Nomeclatura de Horario</Typography>
                        <Typography className='mt-1' color="textSecondary" variant="subtitle2">{currentPeriodName}</Typography>
                        {
                            scheduleWithModalidad.length > 0 ?
                                <Typography style={{ overflowX: 'auto' }} component="div">
                                    <DynamicTable tableHeaders={colNames} tableRows={scheduleWithModalidad.filter(s => s.periodo === String(currentPeriod))} colSpan={colNames.length} />
                                </Typography>
                                :
                                <Typography style={{ textAlign: 'center' }}>
                                    <DynamicTable tableHeaders={colNames} tableRows={[]} colSpan={colNames.length} />
                                    <Typography style={{ backgroundColor: '#111111', padding: '16px', verticalAlign: 'middle' }}>
                                        <CircularProgress style={{ height: '24px', marginTop: '5px', width: '24px' }} color="primary" />
                                    </Typography>
                                </Typography>
                        }
                        <DynamicTable tableHeaders={colNames2} tableRows={list} style={{ width: 'auto' }} />
                    </>
                }
            </Grid>
        </>
    )
}

export default Horario;
