import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, generatePath } from 'react-router-dom';

// reducers 
import { setInstallmentOptions, setOrder, setOrderPriceDetails, setOrderProducts, setPersonInfo, setSelectedInstallmentOption, setUserValidations } from '../../redux/actions/checkoutActions';

// @material-ui
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useMediaQuery, useTheme} from "@material-ui/core";
// components
import CheckoutLoader from './components/CheckoutLoader/CheckoutLoader';

// assets
import useStyles from '../../assets/styles/jss/pages/checkout/checkout';

// components
import CartProductCount from '../Cart/components/CartProductCount/CartProductCount';
import CheckoutWorkflow from './CheckoutWorkflow/CheckoutWorkflow';
import OrderDetails from './OrderDetails/OrderDetails.jsx';
import StudentInfo from './StudentInfo/StudentInfo';
import CheckoutPriceDetails from './CheckoutPriceDetails/CheckoutPriceDetails';
import CheckoutCoupon from './CheckoutCoupon/CheckoutCoupon';
import CheckoutMaintenanceAlert from './components/CheckoutMaintenanceAlert/CheckoutMaintenanceAlert.jsx';
import FinalizePurchase from './CheckoutWorkflow/Workflows/PaymentMethods/FinalizePurchase/FinalizePurchase.jsx';

// paths
import * as paths from '../../routes/paths.js';

// shared
import { log } from '../../shared/utils/console.js';
import { isOrderProcessed } from '../../shared/utils/checkoutHelpers.js';

// sdk
import { CartService, UserService } from "@sdk-point/talisis";
import { getCheckoutCookie, setCheckoutCookie } from '../../shared/utils/checkoutSession.js';
import CheckoutUserValidations from './CheckoutUserValidations/CheckoutUserValidations.jsx';
import clsx from 'clsx';

const cartService = new CartService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY, '');
const userService = new UserService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY, '');

const Checkout = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const checkoutCookie = getCheckoutCookie();
    const { orderProducts, userValidations } = useSelector(state => state.checkoutReducer);
    const [loading, setLoading] = React.useState(true);
    const [acceptPayment, setAcceptPayment] = React.useState(false);
    const [handleFinalizePurchase, setHandleFinalizePurchase] = React.useState(null);
    const theme = useTheme();
    // Detecta si la pantalla está en tamaño desktop según el breakpoint "md"
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const initializeCheckout = async () => {
        try {
            setLoading(true);
            // Regresar usuario al inicio si no hay una orden activa
            const checkedOrderId = checkoutCookie?.orderId > 0 ? checkoutCookie?.orderId : null;
            if (!checkedOrderId) {
                history.push(paths.ROOT_HOME);
            } else {
                // Consultar la orden que está cargada en la cookie
                const response = await cartService.getCartById(checkedOrderId);
                const orderProcessed = isOrderProcessed(response.status_id);
                //  Redirigir a confirmación de compra si la orden ya está procesada
                if (orderProcessed) {
                    history.replace(generatePath(paths.PAYMENT_CONFIRMATION, { order_id: checkedOrderId }));
                    return;
                } else {
                    // Actualizar el reducer de la compra con los detalles de los productos
                    dispatch(setOrder(response));
                    dispatch(setOrderProducts(response?.cart_details));
                    dispatch(setUserValidations({}));
                    // Validar los datos del usuario antes de proceder al pago
                    if (response?.person_id > 0) {
                        const validationResponse = await userService.validateBeforePaymentGateway(response.person_id);
                        if (!validationResponse.status) {
                            const phoneNumber = validationResponse.person_info.phone_number;
                            dispatch(setUserValidations({ phoneNumber }));
                            setLoading(false);
                            return;
                        }
                    }
                    // Procesar los totales del carrito para el checkout
                    const personId = response?.person_id || null;
                    const checkoutProcess = await cartService.shoppingCartProcess(checkedOrderId, personId, null);
                    // Procesar la orden y actualizar estados/reducers
                    await processOrderData(checkoutProcess, personId);
                }
            }
            setLoading(false);
        } catch (error) {
            log('error', error)
        }
    };

    const processOrderData = async (checkoutProcess, personId) => {
        // Procesar los datos de la persona en el checkout
        const personInfo = checkoutProcess?.person_info || {};
        const userInfo = {};
        if (personInfo && Object.keys(personInfo).length > 0) {
            userInfo.userName = personInfo.name;
            userInfo.phone_number = personInfo.phone_number;
            userInfo.email = personInfo.email_address;
            userInfo.person_id = personId;
        }
        // Actualizar reducer de la persona
        dispatch(setPersonInfo(userInfo));
        // Actualizar checkout cookie 
        const checkoutCookie = getCheckoutCookie();
        setCheckoutCookie({
            ...checkoutCookie,
            personInfo: userInfo
        });
        // Actualizar detalles de precios en la orden
        dispatch(setOrderPriceDetails({
            total: checkoutProcess?.total,
            subtotal: checkoutProcess?.subtotal,
            bonuses: checkoutProcess?.bonuses,
            discounts: checkoutProcess.discounts,
            savings: checkoutProcess?.savings,
            next_payment_date: checkoutProcess?.next_payment_date
        }));
        // Actualizar opciones de plazo msi
        dispatch(setInstallmentOptions([...checkoutProcess?.msi_options]));
        dispatch(setSelectedInstallmentOption(1));
    };

    React.useEffect(() => {
        initializeCheckout()
    }, []);

    // Mostrar solo el componente de carga o el de errores, dependiendo del estado
    if (loading) {
        return <CheckoutLoader loading={loading} />;
    }

    // Verifica si todas las validaciones del usuario son correctas
    const validateUserInfo = () => {
        return Object.values(userValidations).every(value => value === true);
    };

    // Mostrar este componente para las validaciones del usuario
    if (!validateUserInfo()) {
        return <CheckoutUserValidations processOrderData={processOrderData} onCheckoutLoading={setLoading} />;
    }
     
    return <Box className={classes.container}>
        <CheckoutMaintenanceAlert display={false} />
        <Grid container>
            <Grid item xs>
                <Box className={classes.cartProductCount}>
                    <CartProductCount products={orderProducts} />
                </Box>
            </Grid>
            <Grid container item spacing={4}>
                <Grid item xs={12} md={6}>
                    <CheckoutWorkflow  acceptPayment={acceptPayment} setAcceptPayment={setAcceptPayment} setHandleFinalizePurchase={setHandleFinalizePurchase}/>
                </Grid>
                <Grid item xs={12} md={6} className={clsx(!isDesktop && classes.summaryContainer)}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <OrderDetails />
                        </Grid>
                        <Grid item xs={12}>
                            <StudentInfo />
                        </Grid>
                        <Grid item xs={12}>
                            <CheckoutPriceDetails />
                        </Grid>
                        <Grid item xs={12}>
                            <CheckoutCoupon />
                        </Grid>
                        {!isDesktop && <Grid item xs={12}>
                            <FinalizePurchase
                                acceptPayment={acceptPayment}
                                onFinalizePurchase={handleFinalizePurchase} />
                        </Grid>}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Box>
};

export default Checkout;