import React from 'react';
// styles
import useStyles from '../../../assets/styles/jss/pages/exploreHome/bannerStyle'

//material
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

//assets/images
import { Hidden, useMediaQuery } from '@material-ui/core';
import ResponsiveContainer from '../../../shared/components/ResponsiveContainer/ResponsiveContainer';

const Banner = (props) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box className={classes.bgImage}/>
            <ResponsiveContainer className={classes.responsiveContainer}>
                <Box className="title">
                    <Typography variant="h1" className='text-gray-100'>Estudia Bachillerato, Licenciatura o Maestría online a tu propio ritmo con validez ante la SEP</Typography>
                </Box>
                <Box display='flex' gridGap={4} flexDirection='column' className='content'>
                    <Typography variant='h3' className='text-blue-300'>Más de 16 Programas de Licenciatura y Maestría</Typography>
                    <Hidden xsDown>
                        <Typography variant='subtitle1' className='text-white'>En Educación, Negocios, Tecnología, entre otras.</Typography>
                    </Hidden>
                    <Box display='flex' alignItems='baseline' sx={{justifyContent:{xs: 'center', md: 'start'}}}>
                        <Typography variant='subtitle2' className='text-orange-300'>Desde</Typography>
                        <Typography variant='h2' className='text-orange-300'>&nbsp;$899 MXN</Typography>
                        <Typography variant='subtitle1' className='text-orange-300'>&nbsp;por mes</Typography>
                    </Box>
                </Box>
                <Box className={classes.actions}>
                    <Button variant="contained" color="primary" onClick={props.onClickLogin}>Registrate gratis</Button>
                    <Link href="https://quienessomos.talisis.com/" target="_blank" underline="hover" color="secondary" variant="body2" className="semi-bold">¿Qué es Talisis?</Link>
                </Box>
            </ResponsiveContainer>
        </Box>
    );
};

export default Banner;