import * as userTypes from '../types/userTypes';
import { setSessionCookie } from '../../shared/utils/Sessions';

import { UserService } from '@sdk-point/talisis';
const UserServiceSDK = new UserService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);


export const USER_PROPERTIES = {
    ACCEPT_TERMS_CONDITIONS: 'term_cond'
} 

export const applyUser = (user) => async (dispatch) => {
    dispatch({
        type: userTypes.APPLY_USER,
        payload: user
    });

    // Si no hay `person_id`, no hacemos nada más
    if (!user?.person_id) {
        // Eliminar userDetails de localStorage y de Redux si el usuario no tiene person_id
        localStorage.removeItem('userDetails');
        dispatch({
            type: userTypes.APPLY_USER_DETAILS,
            payload: {}
        });
        return;
    }

    // Intentar obtener los detalles del usuario desde localStorage
    const storedUserDetails = localStorage.getItem('userDetails');
    const userDetailsFromStorage = storedUserDetails ? JSON.parse(storedUserDetails) : null;

    // Verificar si el user.person_id es igual al userDetails.person_id en localStorage
    if (userDetailsFromStorage && user.person_id == userDetailsFromStorage.id) {
        // Si coinciden, usar los detalles del localStorage
        dispatch({
            type: userTypes.APPLY_USER_DETAILS,
            payload: userDetailsFromStorage
        });
    } else {
        // Si no coinciden, hacer la petición para obtener los detalles actualizados
        try {
            const userDetails = await UserServiceSDK.getData(user.person_id);
            
            // Guardar los detalles en Redux
            dispatch({
                type: userTypes.APPLY_USER_DETAILS,
                payload: userDetails.user
            });

            localStorage.setItem('userDetails', JSON.stringify(userDetails.user));
        } catch (error) {
            console.error("Error fetching user details: ", error);
        }
    }
};

export const getInterestsProfile = (interests) => (dispatch) => {
    dispatch({
        type: userTypes.GET_INTEREST,
        payload: interests
    });
};


export const getFavInterests = (favInterests) => (dispatch) => {
    dispatch({
        type: userTypes.GET_FAV_INTEREST,
        payload: favInterests
    });
};

// todo: Borrar saveFavInterests
export const saveFavInterests = (favInterests) => (dispatch) => {
    dispatch({
        type: userTypes.GET_FAV_INTEREST,
        payload: {
            data_set: 'fav_interest_areas',
            favInterests
        }
    });
};

export const setInterests = (interests) => (dispatch, getState) => {
    const { user } = getState().userReducer;
    const _user = {...user, interests};

    setSessionCookie(_user);

    dispatch({
        type: userTypes.SET_INTERESTS,
        payload: interests
    });
};

export const getInterests = () => (dispatch, getState) => {
    const state = getState();
    const interests = state.user.interests;
    dispatch({
        type: userTypes.GET_INTERESTS,
        payload: interests
    });
};

export const updateMembership = (membership_id) => (dispatch, getState) => {
    const { user } = getState().userReducer;
    const _user = {...user, membership_id};

    setSessionCookie(_user);

    dispatch({
        type: userTypes.UPDATE_MEMBERSHIP,
        payload: membership_id
    });
};

export const updateUserProperty = (propName, value) => {
    return (dispatch, getState) => {
        const { user } = getState().userReducer; // Ajusta esto según cómo tengas estructurado tu estado
  
      const updatedPerson = {
        ...user,
        [propName]: value,
      };

      setSessionCookie(updatedPerson);
  
      dispatch(applyUser(updatedPerson));
    };
};
