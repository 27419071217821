import React from 'react';
// styles
import useStyles from '../../../assets/styles/jss/pages/exploreHome/bannerTestingStyle'

//material
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

//assets/images
import ResponsiveContainer from '../../../shared/components/ResponsiveContainer/ResponsiveContainer';
import { Hidden } from '@material-ui/core';

import { eventData, eventTracker } from '../../../shared/components/EventTracker/EventTracker';
import Banner from './Banner';

const banners = [
    {   img: '/images/home/banner-B1.png',
        desc: 'Entérate también de toda nuestra oferta educativa en cursos, bachillerato, licenciaturas y maestrías 100% en línea.', 
        btn1:{
            title: 'Conoce más',
            link: ''
        },
        btn2:{
            title: 'Para Empresas',
            link: 'https://b2b.talisis.com'
        }
    },
    {   img: '/images/home/banner-B2.png', 
        desc: 'Estudia Bachillerato, Licenciatura o Maestría 100% online a tu propio ritmo con validez ante la SEP.',
        btn1:{
            title: 'Regístrate aquí',
            link: ''
        },
        btn2:{
            title: '¿Qué es Talisis?',
            link: 'https://quienessomos.talisis.com'
        }
    },
    {   img: '/images/home/banner-B3.png', 
        desc: '¿Quieres retener el talento y fortalecer la lealtad en tu empresa?',
        btn1:{
            title: 'Solicitar Demo',
            link: 'https://b2b.talisis.com'
        },
        btn2:{
            title: 'Ver video',
            link: 'https://youtu.be/vzcH1fRQnEo?si=_bHZ9bVAu8YvoUBc'
        }
    },
]



const BannerTesting = ({onClickLogin, type}) => {
    const classes = useStyles();

    const handleToEvent = () => {

        eventData['source'] = 'ExploreHome';
        eventData['NameBanner'] = banners[type].btn1.title;
        eventData['LinkBanner'] = banners[type].btn1.link;
        eventTracker('Banner', eventData, ['mixpanel']);
    };

    if(type === 3){
        return <Banner onClickLogin={onClickLogin} />
    }

    return (
        <Box className={classes.root}>
            <div className={classes.bgResponsive}></div>
            <Hidden smDown>
                <img src={banners[type].img} className={classes.bg}/>
            </Hidden>
            <ResponsiveContainer className={classes.responsiveContainer}>
                <Box width={600} display='flex' gridGap={24} flexDirection='column' className={classes.container}>
                    <img src='/images/home/todo-talisis.svg' className={classes.imgPrimary}/>
                    <img src='/images/home/a-traves-de.svg' className={classes.imgSecondary}/>
                    <Typography variant='subtitle1' className='text-orange-300' align='left'>{banners[type].desc}</Typography>

                    <Box className={classes.actions}>
                        {
                            banners[type].btn1.link
                            ?<Button variant="contained" color="primary" href={banners[type].btn1.link} onClick={handleToEvent} target="_blank">{banners[type].btn1.title}</Button>
                    
                            :<Button variant="contained" color="primary" onClick={onClickLogin}>{banners[type].btn1.title}</Button>
                        }
                        <Link href={banners[type].btn2.link} target="_blank" underline="hover" color="secondary" variant="body2" className="semi-bold">{banners[type].btn2.title}</Link>
                    </Box>
                </Box>
            </ResponsiveContainer>
        </Box>
    );
};

export default BannerTesting;