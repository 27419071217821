import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

// material components
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import { useDispatch } from 'react-redux';
import { setActiveTab } from '../../../redux/actions/tabsActions';

export default function HorizontalTabs(props) {
	const {children, onChange, activeTab, tabs, actions, disabledAll, ...rest} = props;
	const dispatch = useDispatch();
    const location = useLocation();

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const tabIndex = parseInt(searchParams.get('tab'));

		if (tabIndex) {
			dispatch(setActiveTab(tabIndex));
		}
	}, [dispatch, location.search]);

  	return (
		<Box width="100%">
			<Tabs 
				value={activeTab} 
				onChange={(event, value) => onChange(value)} 
				aria-label="simple tabs"
				indicatorColor="primary"
                {...rest}
                className="no-print"
				textColor="inherit"
				variant="scrollable"
				scrollButtons="auto"
			>
				{tabs.map((it, index) => {
					// Verifica si es un componente React válido
					if (React.isValidElement(it)) {
						return (
							<Tab 
								key={index}
								label={it}  // Renderiza directamente el componente si es un elemento React
								id={`scrollable-auto-tab-${index}`}
								aria-controls={`scrollable-auto-tabpanel-${index}`}
								disabled={disabledAll}
								value={index}
							/>
						);
					}

					// Si no es un componente React, trata el valor y el label normalmente
					const value = typeof it === 'object' && it !== null ? it.value : index;
					const label = typeof it === 'object' && it !== null ? it.label : it;

					return (
						<Tab 
							key={index}
							label={label}
							id={`scrollable-auto-tab-${index}`}
							aria-controls={`scrollable-auto-tabpanel-${index}`}
							disabled={disabledAll}
							value={value}
						/>
					);
				})}
					{actions}
			</Tabs>
			{children}
		</Box>
  	);
}


HorizontalTabs.defaultProps = {
    activeTab: 0,
	tabs: [],
	disabledAll: false
};

HorizontalTabs.propTypes = {
	disabledAll: PropTypes.bool,
    children: PropTypes.element,
    onChange: PropTypes.func,
    activeTab: PropTypes.number,
	tabs: PropTypes.array.isRequired,
	actions: PropTypes.element,
	children: PropTypes.element
};