import React from 'react';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Icon from '@material-ui/core/Icon';
import { useTheme } from '@material-ui/core/styles';

// assets
import LogoVisa from "../../../../../../assets/images/banks/visa-checkout-blanco.svg";
import LogoMaster from "../../../../../../assets/images/banks/master-card-logo.svg";
import LogoAmex from "../../../../../../assets/images/banks/american-expre-texto.svg";
import LogoSPEI from "../../../../../../assets/images/banks/spei.svg";

// styles
import useStyles from '../../../../../../assets/styles/jss/pages/market/PaymentConfirmation';

// sdk
import { ORDER_STATUS, PAYMENT_METHODS } from '@sdk-point/talisis';

// helper
import { getLastOrderPayment } from '../../../paymentConfirmationHelper';

const PaymentMethod = ({ order, orderPayments }) => {
    const theme = useTheme();
    const classes = useStyles();
    const breakpoint = useMediaQuery(theme.breakpoints.down("xs"));
    const paymentMethod = getLastOrderPayment(orderPayments);
    let paymentDetail={}
    if(paymentMethod?.payment_method_id === PAYMENT_METHODS.CARD) {
        try {
            // Convertir la cadena JSON en un objeto
            const conkeObject = JSON.parse(paymentMethod?.metadata);
            paymentDetail = conkeObject?.conekta?.payment_method
            // Ahora puedes acceder a sus propiedades
            console.log(conkeObject?.conekta?.payment_method); 
        } catch (error) {
            console.error("Error al parsear JSON:", error);
        }  
    }
    return (
        <Card className='bg-fill-stroke-400'>
            <Box display="flex" flexDirection="column" px="24px" pt="24px" pb="30px">
                <Typography variant="h5" className="text-gray-100">Método de pago</Typography>
                <Box display="flex" justifyContent="space-between">
                    {paymentMethod?.payment_method_id === PAYMENT_METHODS.CARD &&
                        <React.Fragment>
                            <Grid container className='mt-2' justifyContent='flex-start' alignItems='center'>
                                <Grid item xs={2}>
                                    <Box display="flex">
                                        {paymentDetail?.brand === 'visa' && <img style={{ width: breakpoint ? "25.8px" : "55.8px", maxHeight: breakpoint ? "15px" : "30px" }} alt="visa-logo" src={LogoVisa} />}
                                        {paymentDetail?.brand === 'mastercard' && <img style={{ width: breakpoint ? "25.8px" : "55.8px", maxHeight: breakpoint ? "15px" : "30px", marginLeft: "10px" }} alt="mastercard-logo" src={LogoMaster} />}
                                        {paymentDetail?.brand === 'american_express' &&  <img style={{ width: breakpoint ? "25.8px" : "55.8px", maxHeight: breakpoint ? "15px" : "30px" }} alt="amex-logo" src={LogoAmex} />}
                                    </Box>
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="body2">Tarjeta de {paymentDetail?.type ==='debit'? 'débito':'crédito'} {paymentDetail?.brand.toUpperCase()} con terminación {paymentDetail?.last4}</Typography>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }
                    {paymentMethod?.payment_method_id === PAYMENT_METHODS.SPEI &&
                        <Box display="flex" mt="16px" alignItems="center">
                            <Box mr="16px" display="flex">
                                <img style={{ width: "55.8px", maxHeight: "30px" }} alt="spei-logo" src={LogoSPEI} />
                            </Box>
                            <Typography variant="body2">Pago por transferencia electrónica</Typography>
                        </Box>
                    }
                    {paymentMethod?.payment_method_id === PAYMENT_METHODS.CASH &&
                        <Box display="flex" mt="16px" alignItems="center">
                            <Box display="flex" mr="16px">
                                <Icon style={{ fontSize: "16px", lineHeight: "16px" }} alt="wallet" className='ri-wallet-3-line' />
                            </Box>
                            <Typography variant="body2">Pago en efectivo</Typography>
                        </Box>
                    }
                    {order?.status_id === ORDER_STATUS.PENDING_PAYMENT && <Typography variant="caption" className={`${classes.pending} bg-warning-500`}>Pendiente</Typography>}
                </Box>
            </Box>
        </Card>
    )
};

export default PaymentMethod;