import React from 'react';

import BrandLandingPageVideoUnid from './BrandLandingPageVideo/BrandLandingPageVideoUnid';
import BrandLandingPageVideoUerre from './BrandLandingPageVideo/BrandLandingPageVideoUerre';
import BrandLandingPageVideoHarmonHall from './BrandLandingPageVideo/BrandLandingPageVideoHarmonHall';

import { makeStyles } from '@material-ui/core/styles';
import ResponsiveContainer from '../../../shared/components/ResponsiveContainer/ResponsiveContainer';

const useStyles = makeStyles((theme) => ({
    container:{
        padding: '166px 0 120px',
        [theme.breakpoints.up('sm')]:{
            background: props => props.brand === "harmonhall" ? 'transparent' : theme.palette.colors.fillStroke[400],
            padding: '80px 15px'
        },
    },
}));

const GenericBrandVideo = (props) => {
    const { brand } = props;
    const classes = useStyles({brand});

    return (
        <ResponsiveContainer className={classes.container}>
            {/* {brand === 'u-erre' && <BrandLandingPageVideoUerre /> } */}
            {brand === 'unid' && <BrandLandingPageVideoUnid /> }
            {brand === 'harmonhall' && <BrandLandingPageVideoHarmonHall />}
        </ResponsiveContainer>
    )

};

export default GenericBrandVideo;