import { PRODUCT_TYPES } from "@sdk-point/talisis";

// Función para validar si solo hay productos tipo "curso"
const hasOnlyCourses = (orderDetails) => {
    return orderDetails.every(item => item.product_type_id === PRODUCT_TYPES.CURSO);
};

// Función para validar si tiene un producto tipo "membresía"
const hasMembership = (orderDetails) => {
    return orderDetails.some(item => item.product_type_id === PRODUCT_TYPES.MEMBRESIA);
};

// Función para validar si tiene mínimo dos productos: uno tipo "membresía" y otro tipo "programa"
// const hasMembershipAndProgram = (orderDetails) => {
//     const hasMembership = orderDetails.some(item => item.product_type_id === PRODUCT_TYPES.MEMBRESIA);
//     const hasProgram = orderDetails.some(item => item.product_type_id === PRODUCT_TYPES.PROGRAMA);
//     return hasMembership && hasProgram;
// };

// Función principal de validación considerando el peso de las condiciones
export const validateOrderDetails = (orderDetails = []) => {
    /*if (hasMembershipAndProgram(orderDetails)) {
        return PRODUCT_TYPES.PROGRAMA;
    } else */
    if (hasMembership(orderDetails)) {
        return PRODUCT_TYPES.MEMBRESIA;
    } else if (hasOnlyCourses(orderDetails)) {
        return PRODUCT_TYPES.CURSO;
    }
    return null;
};

export const getDefaultProductOfType = (orderDetails = [], productTypeId) => {
    return orderDetails.find(item => item?.product_type_id === productTypeId) || null;
};

export const getLastOrderPayment = (orderPayments = []) => {
    return orderPayments.length > 0 ? orderPayments[orderPayments.length - 1] : null;
};

export const cleanProductName = (name='') => {
    return name.replace(/\s*\(.*?\)\s*/g, '').trim();
} 

export const determineVariableOrder = {
    COURSE_ONLY: 1,
    MEMBERSHIP_ONLY: 2,
    MEMBERSHIP_PROGRAM: 3,
    MEMBERSHIP_COURSE: 4,
    MEMBERSHIP_ADDON: 5,
    MEMBERSHIP_ADDON_PROGRAM: 6,
    MEMBERSHIP_ADDON_COURSE: 7,
}

export const determineVariable = (orderDetails) => {
    let hasMembership = false;
    let hasCourse = false;
    let hasProgram = false;
    let hasAdd = false;
    // Iterate through products to identify the types
    orderDetails.forEach(product => {
        switch (product.product_type_id) {
            case PRODUCT_TYPES.MEMBRESIA:
                hasMembership = true;
                break;
            case PRODUCT_TYPES.CURSO:
                hasCourse = true;
                break;
            case PRODUCT_TYPES.PROGRAMA:
                hasProgram = true;
                break;
            case PRODUCT_TYPES.LICENCIA:
                hasAdd = true;
                break;
            default:
                break;
        }
    });
    // Determine the corresponding variable
    if (!hasMembership && hasCourse && !hasProgram && !hasAdd) {
        return determineVariableOrder.COURSE_ONLY; // Course only
    } else if ( hasMembership && !hasCourse && !hasProgram && !hasAdd) {
        return determineVariableOrder.MEMBERSHIP_ONLY; // Membership only
    } else if (hasMembership && hasProgram && !hasCourse && !hasAdd) {
        return determineVariableOrder.MEMBERSHIP_PROGRAM; // Membership + Program
    } else if (hasMembership && hasCourse && !hasProgram && !hasAdd) {
        return determineVariableOrder.MEMBERSHIP_COURSE; // Membership + Course
    } else if (hasMembership && !hasCourse && !hasProgram && hasAdd) {
        return determineVariableOrder.MEMBERSHIP_ADDON; // Membership + Add
    } else if (hasMembership && hasAdd && hasProgram && !hasCourse) {
        return determineVariableOrder.MEMBERSHIP_ADDON_PROGRAM; // Membership + Add + Program
    } else if (hasMembership && hasAdd && hasCourse && !hasProgram) {
        return determineVariableOrder.MEMBERSHIP_ADDON_COURSE; // Membership + Add + Course
    }
    return null; // No matching combination
} 