import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import TimeAgo from 'react-timeago'
import esSettings from 'react-timeago/lib/language-strings/es'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'

// @material-ui/core components
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

// core components
import LoaderContent from "../../../../../../shared/components/Loader/LoaderContent"

// redux
import { connect } from "react-redux";
import NothingFound from "../../../../../../shared/components/NothingFound/NothingFound";
import { log } from '../../../../../../shared/utils/console.js'
//SDK
import { NotificationService } from '@sdk-point/talisis'; 
import { Badge } from '@material-ui/core';
import DOMPurify from 'dompurify';

import FloatingMenuNotifications from '../../FloatingMenuNotifications/FloatingMenuNotifications';

const NotificationServiceSDK = new NotificationService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const formatter = buildFormatter(esSettings)

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '36ch',
        backgroundColor: theme.palette.background.paper,
    },
    block: {
        display: 'block',
        fontSize: 12,
        marginBottom: 8
    },
    list: {
        width: "352px",
        padding: "15px",
        minHeight: "500px"
    },
    notification: {
        padding:'16px',
        gap:'16px'
    },
    notificationContainer: {
        background: theme.palette.colors.grey[400],
        borderRadius: "8px",
        marginBottom: "10px"
    },
    readedNotificationContainer: {
        background: theme.palette.colors.grey[400],
        borderRadius: "8px",
    },
    timeAgo:{
        color: theme.palette.colors.blue[400],
        paddingTop: '10px',
        fontSize: 12,
    },
    badgeNotification:{
        backgroundColor:theme.palette.colors.blue[300],  
        color: 'white'
    },
    chip:{
        margin: '0px 16px 10px 0px !important',
        background: theme.palette.black,
        border: `0.5px solid ${theme.palette.colors.fillStroke[200]}`,
        borderRadius: 24,
        '&.active':{
            background: theme.palette.colors.fillStroke[300] + ' !important',
            border: `0.5px solid ${theme.palette.colors.fillStroke[100]} !important`,
        }
    },
    drawerHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0px 16px',
    },
}));

const RedirectToNewWindow = React.forwardRef(({ navigate, ...props }, ref) =>
    <Link ref={ref} href={props.to} target="_blank">{props.children}</Link>
);

function isUrl(s) {   
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    return regexp.test(s);
}

const NotificationsList = (props) => {
    const { person_id } = props.user;
    const classes = useStyles();
    const [loader, setLoader] = React.useState(true);
    const [notifications, setNotifications] = React.useState([]);
    const [listNotifications, setListNotifications] = React.useState([]);
    const [leidas, setleidas] = React.useState(true);

    const reciveNotifications = async () => {
        await NotificationServiceSDK.getNotifications(person_id)
            .then(data => {
                setLoader(false)
                setNotifications(data)
                setListNotifications(data.filter(notification => !notification.read_date))
                NotificationServiceSDK.markAsRead(person_id)
            })
            .catch(error => log(error));
    }


    React.useEffect(() => {
        reciveNotifications();
        handleClickRead();
    }, []);

    const handleClickNoRead = () => {
        setListNotifications(notifications.filter(notification => !notification.read_date))
        setleidas(false);
    };

    const handleClickRead = () => {
        setListNotifications(notifications.filter(notification => notification.read_date))
        setleidas(true)
    };

    const nothing = {
        type: 'notifications',
        caption: 'No tienes notificaciones Nuevas'
    }

    return (
        <LoaderContent loading={loader}>
            {
                notifications.length ?
                    <React.Fragment>
                        <FloatingMenuNotifications
                            labelTrue="Leidas"
                            labelFalse="No leidas"
                            handleClickTrue={handleClickRead}
                            handleClickFalse={handleClickNoRead}
                            list={listNotifications}
                            nothingInfo={nothing}
                            result={leidas}
                        >
                            {listNotifications.map((notification, index) => {
                                const notificationContent = DOMPurify.sanitize(notification.content);
                                return(
                                    <React.Fragment key={index}>
                                        {notification.action_required ?
                                            <Link component={isUrl(notification.url) ? RedirectToNewWindow : RouterLink } to={notification.url ? notification.url : ''}>
                                                <ListItem button className={classes.notification}>
                                                    <ListItemText
                                                        primary={<Typography variant="h6">{notification.title}</Typography>}
                                                        secondary={
                                                            <React.Fragment>
                                                                <Typography component="span" variant="body2" className={classes.block} color="textPrimary" dangerouslySetInnerHTML={{ __html: notificationContent }} />
                                                                <TimeAgo date={notification.created_date.replace(/[TZ]/g,' ')} formatter={formatter} className={classes.timeAgo} />
                                                            </React.Fragment>
                                                        }></ListItemText>
                                                        {
                                                            !leidas && <Badge classes={{ badge: classes.badgeNotification }} badgeContent=''></Badge>
                                                        }
                                                </ListItem>
                                            </Link>
                                            :
                                            <ListItem button className={classes.notification}>
                                                <ListItemText
                                                    primary={<Typography variant="h6">{notification.title}</Typography>}
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography component="span" variant="body2" className={classes.block} color="textPrimary" dangerouslySetInnerHTML={{ __html: notificationContent }} />
                                                            <TimeAgo date={notification.created_date.replace(/[TZ]/g,' ')} formatter={formatter} />
                                                        </React.Fragment>
                                                    }></ListItemText>
                                                    {
                                                        !leidas && <Badge classes={{ badge: classes.badgeNotification }} badgeContent=''></Badge>
                                                    }
                                            </ListItem>
                                            }
                                    </React.Fragment>
                                )
                            })}
                        </FloatingMenuNotifications>
                    </React.Fragment>
                :
                    <NothingFound type="notifications" caption="No tienes notificaciones Aun"></NothingFound>
            }
        </LoaderContent>
    );
};
const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(NotificationsList);