import React from 'react';
import clsx from 'clsx';
 
// @material-ui/core
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// shared components
import { startCheckout } from '../../../../shared/utils/legacyStartCheckout';
import objectIsEmpty from "../../../../shared/utils/objectIsEmpty";
import { getSessionCookie } from "../../../../shared/utils/Sessions";
import MembershipDetails from '../../../../shared/components/MembershipDetails/MembershipDetails';
import { eventTracker } from '../../../../shared/components/EventTracker/EventTracker';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        padding: '24px',
    },
    rootYear: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        padding: "24px",
    },
    membershipButton: {
        display: "flex",
        flex: "1 1 auto",
        alignItems: "flex-end"
    },
    subscribeButton: {
        "&:hover": {
            border: '0.5px solid #9F6FFB !important'
        }
    },
    membershipSelectedCheck: {
        position: "absolute",
        fontSize: "24px",
        right: "16px",
        zIndex: 1
    }
}));

const MembershipDefaultButtons = ({ isPopular, onClick, buttonText }) => {
    const classes = useStyles();
    return <Button
        fullWidth
        style={{paddingLeft:0, paddingRight:0}}
        className={clsx({ [classes.subscribeButton]: !isPopular })}
        variant={isPopular ? "contained" : "outlined"}
        color={isPopular ? "primary" : "secondary"}
        onClick={onClick}>
        {buttonText}
    </Button>
};

const MembershipCheckButtons = ({ isPopular, onClick, buttonText, isSelected }) => {
    const classes = useStyles();
    return <Box width="100%" display="flex" alignItems="center" position="relative">
        {isSelected && <i className={clsx('ri-check-line text-success-300', classes.membershipSelectedCheck)} />}
        <Button
            fullWidth
            className={clsx({ [classes.subscribeButton]: !isPopular })}
            variant={isPopular ? "contained" : "outlined"}
            color={isPopular ? "primary" : "secondary"}
            onClick={onClick}>
            {buttonText} 
        </Button>
    </Box>
};

const MembershipPlanCardContent = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const { id, popular, previous, user, fees, membershipId, membershipSelected, onMembershipSelect, feePlan, isReferrals, actionType = "default", customCheckoutCookie = {} } = props;
     const session = getSessionCookie();
    const isLogged = !objectIsEmpty(session);
    const tempFeeCard = fees.find((value, index) => value.term_slug === feePlan); 
    const buttonText= id === "freemium" ? "Empezar ahora" : `Suscribirse por $${new Intl.NumberFormat('es-MX').format(tempFeeCard?.amount)} MXN`;
   
    const PLAN_ANUAL = "ANU";
 
    const handleSelect = () => {
        onMembershipSelect(membershipId);
    };

    const handleSubscribe = () => {
        if (id !== "freemium") {
            let tempFee = fees.find((value, index) => value.term_slug === feePlan);
            let tempFeeId = tempFee?.id || 0;
            const membershipInfo = {
                membershipId: membershipId, // 1, 2, 6, 13
                membershipFee: tempFeeId, // 1, 2, 3, 4
            }
            const userLogged = isLogged && !objectIsEmpty(user);
            const productInfo = [
                { itemType: "membershipId", membershipInfo }
            ];

            let paymentPeriod = tempFeeCard?.term_description?.toLowerCase();
            trackPurchase(id, paymentPeriod);

            startCheckout({
                user: user,
                orderProducts: productInfo,
                history,
                isLogged: userLogged,
                customCheckoutCookie
            });
        }
        else {
            trackPurchase("freemium", null);

            history.replace({
                pathname: "/signup"
            });
        }
    };

    const trackPurchase = (membership, paymentPeriod) => {
        const eventData = {};
        eventData['source'] = 'planes';
        eventTracker('cta_registro', eventData, ['hubspot', 'google']);
        eventData['tipoMembresia'] = membership;
        eventData['periodoPago'] = paymentPeriod;
        eventTracker('comprar_membresia', eventData, ['mixpanel', 'hubspot','google']);
    };

    return (
        <Box className={clsx({ [classes.root]: feePlan !== PLAN_ANUAL, [classes.rootYear]: feePlan === PLAN_ANUAL })}>
            <MembershipDetails id={id} title={previous ? `Todo lo ${previous.name} más` : ""} page="landing" />
            {
                actionType === "checker" ? <Box className={classes.membershipButton}>
                    <MembershipCheckButtons onClick={handleSelect} buttonText="Seleccionar" isPopular={popular === id} isSelected={membershipId === membershipSelected} />
                </Box> : <Box className={classes.membershipButton}>
                    <MembershipDefaultButtons onClick={handleSubscribe} buttonText={buttonText} isPopular={popular === id} />
                </Box>
            }
            {
                isReferrals ? <Box className='mt-3'>
                    <Typography variant="caption" className="text-fill-stroke-100 font-size-10">La promoción solo está disponible para personas que no han tenido una cuenta en Talisis.</Typography>
                </Box>
                : <Box className='text-center'>
                    { 
                     ((tempFeeCard?.term_months*tempFeeCard?.old_amount_per_month)> tempFeeCard?.amount) &&
                       <Typography variant="caption" className="text-gray-100 font-size-12">Pagando {tempFeeCard?.term_description?.toLowerCase()}mente ahorras <Typography  className={clsx('semi-bold', 'text-gray-100', 'font-size-12')} style={{display: "inline"}}>${new Intl.NumberFormat('es-MX').format((tempFeeCard?.term_months*tempFeeCard?.old_amount_per_month)-tempFeeCard?.amount)}</Typography></Typography>
                    }
                </Box>
            }
        </Box >
    );
};

export default MembershipPlanCardContent;