import React from 'react';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// styles
import useStyles from '../../../../../../assets/styles/jss/pages/market/PaymentConfirmation';

const NextPaymentDate = ({ nextPayment}) => {
    const classes = useStyles(); 
    return (
        <Box className={classes.boxNextPaymentDate} p="8px" textAlign="center">
            <Typography variant="body1" className='text-white'>Fecha próximo pago: {nextPayment}</Typography>
        </Box>
    );
};

export default NextPaymentDate;