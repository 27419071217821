import React from 'react';

// @material-ui/core
import Box from '@material-ui/core/Box';

// components
import PaymentStatus from './components/PaymentStatus';
import PaymentInstructions from './components/PaymentInstructions';
import ProductDetail from './components/ProductDetail';

// sdk
import { ORDER_STATUS } from '@sdk-point/talisis';

const PaymentConfirmationHeader = ({ order, orderDetails, orderPayments }) => {
    return <Box>
        <Box mb="32px"><PaymentStatus order={order} /></Box>
        <Box mb="64px">
            {order.status_id === ORDER_STATUS.PENDING_PAYMENT && <PaymentInstructions orderDetails={orderDetails} orderPayments={orderPayments} />}
            {order.status_id === ORDER_STATUS.COMPLETED_PAYMENT && <ProductDetail orderDetails={orderDetails} personInfo={order?.person_info}/>}
        </Box>
    </Box>;
}

export default PaymentConfirmationHeader;