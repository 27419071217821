import React from 'react';
import clsx from 'clsx';

// @material-ui
import { Box, Grid, Icon, Typography, useMediaQuery, useTheme  } from '@material-ui/core';
import { CARD_BRANDS, PAYMENT_METHODS, PAYMENT_TYPES } from '@sdk-point/talisis';

// shared
import { cardBrandIcons, paymentMethodIcons, paymentMethodLogos } from './../../../../../../../shared/utils/checkoutHelpers.js';

// assets
import useStyles from '../../../../../../../assets/styles/jss/pages/checkout/checkout';
// Detecta si la pantalla tiene un ancho mínimo de 1024px (desktop)
 
const getPaymentMethodIcons = (paymentMethod, isDesktop) => {
    const { payment_type_id, payment_method_id, metadata } = paymentMethod;
    const paymentIcons = {
        iconStart: null,
        iconEnd: null,
    }

    if (payment_type_id === PAYMENT_TYPES.USER && payment_method_id === PAYMENT_METHODS.CARD) {
        const cardBrandIcon = cardBrandIcons[metadata?.brand];
        paymentIcons.iconStart = <Box display='flex' alignContent='center' alignItems='center' className='mr-3'>
            <img src={cardBrandIcon} alt={metadata.name} height='15px' />
        </Box>;
    }
    else if (payment_type_id === PAYMENT_TYPES.GLOBAL) {
        const paymentMethodIcon = paymentMethodIcons[payment_method_id];
        paymentIcons.iconStart = <Box display='flex' alignContent='center' alignItems='center' className='mr-3'>
            <Icon className={paymentMethodIcon} fontSize='inherit' />
        </Box>;

        if (payment_method_id === PAYMENT_METHODS.CARD) {
            paymentIcons.iconEnd = <Box display='flex' alignContent='center' alignItems='center' className='ml-3' style={{paddingTop: isDesktop ? '0px': '5px'}}>
                <img src={cardBrandIcons[CARD_BRANDS.VISA]} alt={CARD_BRANDS.VISA} height='15px' className='mr-1' />
                <img src={cardBrandIcons[CARD_BRANDS.MASTERCARD]} alt={CARD_BRANDS.MASTERCARD} height='15px' className='mr-1' />
                <img src={cardBrandIcons[CARD_BRANDS.AMERICAN_EXPRESS]} alt={CARD_BRANDS.AMERICAN_EXPRESS} height='15px' />
            </Box>
        }
        else if (payment_method_id === PAYMENT_METHODS.SPEI) {
            const paymentMethodLogo = paymentMethodLogos[payment_method_id];
            paymentIcons.iconEnd = <Box display='flex' alignContent='center' alignItems='center' style={{paddingTop: isDesktop ? '0px': '5px'}}>
                <img src={paymentMethodLogo} alt={PAYMENT_METHODS[payment_method_id]} height='15px' className='ml-3' />
            </Box>
        }
    }

    return paymentIcons;
};

const PaymentLimitLabel = ({classes, paymentMethod}) => {
    return <Grid item className={'pl-2'}>
        <Grid container display="flex" alignContent="center" style={{ flexFlow: "nowrap" }}>
            <Box>
                <Typography variant='caption' className={clsx("semi-bold", classes.paymentMethodDisabled)}>
                    <Box display="flex" justifyContent="center">
                        <i className={clsx("ri-error-warning-line mr-1", classes.paymentMethodDisabled)} style={{ fontSize: '16px' }} />
                        El monto máximo para pagar en {paymentMethod.description.toLowerCase()} es de ${new Intl.NumberFormat('es-MX').format(paymentMethod.payment_limit)} pesos.
                    </Box>
                </Typography>
            </Box>
        </Grid>
    </Grid>
};

const PaymentMethodItemLabel = ({ paymentMethod, disablePayment}) => {
    const classes = useStyles();
    const theme = useTheme();
    // Detecta si la pantalla está en tamaño desktop según el breakpoint "md"
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const { description, payment_method_id } = paymentMethod;
    const { iconStart, iconEnd } = getPaymentMethodIcons(paymentMethod, isDesktop);
   
    return (
        <Grid container alignItems='center' className={classes.paymentMethodItemLabel}>
            {payment_method_id!== PAYMENT_METHODS.FREE && <Grid item className={disablePayment && classes.paymentMethodDisabled}>
                {iconStart}
            </Grid>}
            <Grid item>
                <Typography variant='body2' className={disablePayment && classes.paymentMethodDisabled}>{description}</Typography>
            </Grid>
            {iconEnd && <Grid item>
                {iconEnd}
            </Grid>}
            {disablePayment && <PaymentLimitLabel classes={classes} paymentMethod={paymentMethod}/>}
        </Grid>
    );
}

export default PaymentMethodItemLabel;