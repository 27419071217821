import React from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';

// Material
import {
    Icon, Popover,
    List, ListItem, ListItemText, 
    Button,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';

// styles
import { withStyles, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// shared core components
import ProfilePic from '../../../../../shared/components/ProfilePic/ProfilePic'

import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import * as paths from '../../../../../routes/paths'

import {MEMBERSHIPS} from '../../../../../shared/constants/memberships'
import common from '../../../../../shared/constants/common';
import { eventTracker } from '../../../../../shared/components/EventTracker/EventTracker';

// redux
import { connect } from 'react-redux';
import * as menuActions from '../../../../../redux/actions/menuActions'
import { setActiveTab } from '../../../../../redux/actions/tabsActions';
import {INFO_PAGO_TAB_INDEX, PREFERENCIAS_TAB_INDEX, MY_PROFILE_TAB_INDEX} from '../../../../../shared/constants/tabs'
import LogOut from '../../../Login/LogOut/LogOut';
import capitalize from '../../../../../design-system/utils/capitalize';
import { stringContainsAnyOf } from '../../../../../shared/utils/common';
import { getRolesByUser } from '../../../../../services/RoleService';
import { ROLE_PROMOTOR, ROLE_ADMIN_RECEPCION_DOCS } from '../../../../../shared/constants/roles';

// SDK
import { RecordService } from '@sdk-point/talisis'; 
import ReferralPlanMssg from '../../../../../pages/ReferralPlan/components/ReferralPlanMssg';

const RecordServiceSDK = new RecordService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const StyledPopover = withStyles({
    root: {
        width: '100%',
    },
    paper: {
        left: 'unset !important',
        right: 0,
    },
})(Popover);

const useStyles = makeStyles((theme) => ({
    list:{
        width: 276,
        padding: '12px 0 0',
        '& .MuiListItem-divider':{
            borderBottom: `1px solid ${theme.palette.colors.fillStroke[200]}`
        }
    },
    avatar:{
        height: 40,
        width: 40
    },
    iconButton: {
        borderRadius: 0,
        background: 'inherit',
        padding: '12px 12px 13px',
        position: 'relative',
        [theme.breakpoints.only('md')]: {
            padding: '12px 9px 13px',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '12px 11px 13px',
        },
        '& .MuiIcon-root':{
            fontSize: '24px !important'
        },
        '&.active': {
          background: theme.palette.colors.fillStroke[400],
          '&::before': {
            content: '" "',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: 8,
            borderTop: `8px solid ${theme.palette.colors.violet[400]}`,
          },
        },
    },
    nested:{
        '& .level-1':{
            paddingLeft: 32
        },
        '& .level-2':{
            paddingLeft: 64
        }
    },
    listIcon:{
        minWidth: 32,
        '& .MuiIcon-root':{
            fontSize: 20,
            color: theme.palette.colors.gray[400],
        }
    },
    '&.nav-btn-popover':{ 
        background: 'inherit', 
        padding: '12px 12px 13px', 
        position: 'relative', 
        borderRadius: 0, 
        height: props=> props.isLogged ? 65 : 80, 
        '&.active': { background: theme.palette.colors.fillStroke[400], 
                '&::before': { content: '" "', 
                position: 'absolute', 
                top: 0, 
                left: 0, 
                right: 0, 
                height: 8, 
                borderTop: `8px solid ${theme.palette.colors.violet[400]}`, 
            },
        },
    }
}));

const primaryTypographyProps = {variant: 'body1', className: 'text-gray-400'};

const publicMenuItems = [
    { icon: 'ri-account-circle-line', label: 'Ver perfil', path: '/profile', tabIndex: MY_PROFILE_TAB_INDEX},
    { icon: 'ri-bank-line', label: 'Información de pago', path: '/profile', tabIndex: INFO_PAGO_TAB_INDEX},
    { icon: 'ri-settings-4-line', label: 'Preferencias', path: '/profile', tabIndex: PREFERENCIAS_TAB_INDEX},
]

const roleMenuItems = [
    { icon: 'ri-qr-code-line', label: 'Refiere y gana', path: '/refiere-y-gana', tabIndex: null, roles: [ROLE_PROMOTOR]},
    { icon: 'ri-article-line', label: 'Recepción de Documentos', path: '/recepcion-documentos', tabIndex: null, roles: [ROLE_ADMIN_RECEPCION_DOCS]},
]

const ProfileMenu = (props) => {
    const {menu, user, roles} = props;
    const adminMenu = [...menu].filter(it => it.isAdminPage);
    const history = useHistory();
    const classes = useStyles();
    const location = useLocation();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openAdmin, setOpenAdmin] = React.useState(false);
    const [openMenuId, setOpenMenuId] = React.useState(null);

    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const open = Boolean(anchorEl);

    const handleClick = () => {
        setOpenAdmin(!openAdmin);
    };
    const handleClickSubNested = (id) => {
        const menuId = id === openMenuId ? null : id;
        setOpenMenuId(menuId)
    };

    const handleClickIcon = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenAdmin(false);
        setOpenMenuId(null);
    };

    const reloadRedirect = (event, link, tabIndex = null) => {
        event.preventDefault();
        const { path, type = null, externalPath = null, label = null } = link
        if (type === 'external-link'){
            if(label.toLocaleLowerCase().includes('expediente')){
                openSiteExpediente()
            }else{
                window.open(link.externalPath)
            }
            return 
        }
        const gotToPathname = path.split("?")[0];
        const currentPath = location.pathname;
    
        history.push(path);

        if(currentPath === gotToPathname && !tabIndex){
            window.location.reload();
        }else if(tabIndex){
            props.setActiveTab(tabIndex)
        }
    }
    const  openSiteExpediente = async () => {
        try {
            // const cat = await AccountServiceSDK.getCatalogInterestArea();
            const { token } = await RecordServiceSDK.getGenerateToken({ ou:user.company_id,email:user.email} );
            // const url = process.env.REACT_APP_ENV === 'v3' ? common.url_record : common.url_record_dev +'/'+token
            const url = common.url_record+'/'+token
            window.open(url)
        } catch (err) {
        }
    }

    const handleMembershipLanding = () => {
        const eventData = {};
        eventData['source'] = 'dropdown_perfil';
        eventTracker('ver_membresías', eventData, ['mixpanel']);
    };

    const Detail = () => {
        const _name = user.hasOwnProperty('first_name') ? `${user.first_name.split(' ')[0]} ${user.last_name.split(' ')[0]}` : user.userName;
        const name = capitalize(_name);

        return <Box px={2} pb={1} id="profile-menu-root">
            <Box display="flex" mb={1.5} alignItems='center'>
                <Box>
                    <ProfilePic className={classes.avatar} />
                </Box>
                <Box ml="10px">
                    <Typography variant="body1" className="text-gray-400">{name}</Typography>
                    {
                        user?.student_id &&
                        <Typography className="text-dark-black-200 variant-xsmall">Matricula: {user.student_id}</Typography>
                    }
                    <Typography className="text-dark-black-200 variant-xsmall">{roles.map(it => it.role_name).join(' / ')}</Typography>
                </Box>
            </Box>
            {
                ![MEMBERSHIPS.PREMIUM, MEMBERSHIPS.UNID, MEMBERSHIPS.U_ERRE, MEMBERSHIPS.U_ERRE_EJECUTIVA, MEMBERSHIPS.LITE_PLUS, MEMBERSHIPS.IESALUD].some(membership => user.membership_id === membership) &&
                <Button variant="contained" color="primary" onClick={handleMembershipLanding} fullWidth component={RouterLink} to={paths.MEMBRESIAS}>
                    <img src="/images/icons/star_4.svg" alt="star-4" className="offset-margin-right-6"/>
                    Mejora tu plan
                </Button>
            }
        </Box>
    }

    const ListItemAdmin = () => {
        
        return (<>
            <Divider className="bg-fill-stroke-200"/>
            <ListItem button onClick={handleClick}>
                <ListItemIcon className={classes.listIcon}>
                    <Icon className="ri-user-settings-line text-blue-300"/>
                </ListItemIcon>
                <ListItemText primary="Administrador" primaryTypographyProps={{variant: 'body1', className: 'text-blue-300'}}/>
                <Icon className={clsx(openAdmin ? "ri-arrow-drop-up-line" :"ri-arrow-drop-down-line", 'font-size-24 text-blue-300')}/>
            </ListItem>
            <Collapse in={openAdmin} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className={classes.nested}>
                    {
                        adminMenu.map(item => {
                            return (
                                item.isMenu
                                ?
                                <React.Fragment key={uuid()}>
                                    <ListItem button onClick={handleClickSubNested.bind(this, item.id)} className="level-1">
                                        <ListItemText primary={item.label} className="m-0" primaryTypographyProps={primaryTypographyProps}/>
                                        <Icon className={openMenuId === item.id ? "ri-arrow-drop-up-line font-size-24" :"ri-arrow-drop-down-line font-size-24"}/>
                                    </ListItem>
                                    <Collapse in={openMenuId === item.id} timeout="auto" unmountOnExit >
                                        <List component="div" disablePadding>
                                            {
                                                item.children.filter(c => c.isNavLink).map(itemCh =>
                                                    <ListItem button className="level-2" onClick={(event) => reloadRedirect(event, itemCh)} key={uuid()}>
                                                        <ListItemText primary={itemCh.label} className="m-0" primaryTypographyProps={primaryTypographyProps}/>
                                                    </ListItem>
                                                )
                                            }
                                        </List>
                                    </Collapse>
                                </React.Fragment>
                                :
                                <ListItem button className="level-1" key={uuid()} onClick={(event) => reloadRedirect(event, item)}>
                                    <ListItemText primary={item.label} className="m-0" primaryTypographyProps={primaryTypographyProps}/>
                                </ListItem>
                            )
                        })
                    }
                </List>
            </Collapse>
        </>)
    }

    const ListItemRecord = () => (
        <ListItem button onClick={(event) => openSiteExpediente()} key={uuid()}>
            <ListItemIcon className={classes.listIcon}>
                <Icon className={'ri-article-line'}/>
            </ListItemIcon>
            <ListItemText className="m-0" primary={'Expediente'} primaryTypographyProps={primaryTypographyProps}/>
        </ListItem> 
    )

    if(isResponsive){
        return <Button component={RouterLink} to={paths.PERFIL} className={clsx(classes.iconButton)}>
            <ProfilePic className="avatar" />
        </Button>
    }

    return (
        <>
            <Button onClick={handleClickIcon} component={Box} className={clsx(classes.iconButton, {'active': open})} 
                endIcon={<Icon className={open ? "ri-arrow-drop-up-line font-size-24" :"ri-arrow-drop-down-line font-size-24"}/>}>
                <ProfilePic className="avatar" />
            </Button>
            <StyledPopover
                id="notifications"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List className={classes.list} subheader={<Detail />}>
                    {
                        publicMenuItems.map(item =>                             
                            <ListItem button onClick={(event) => reloadRedirect(event, item, item.tabIndex)} key={uuid()}>
                                <ListItemIcon className={classes.listIcon}>
                                    <Icon className={item.icon}/>
                                </ListItemIcon>
                                <ListItemText className="m-0" primary={item.label} primaryTypographyProps={primaryTypographyProps}/>
                            </ListItem>
                        )
                    }
                    {
                        roleMenuItems.filter(item => item.roles.some(role => getRolesByUser().includes(role))).map(item =>   
                            <ListItem button onClick={(event) => reloadRedirect(event, item, item.tabIndex)} key={uuid()}>
                                <ListItemIcon className={classes.listIcon}>
                                    <Icon className={item.icon}/>
                                </ListItemIcon>
                                <ListItemText className="m-0" primary={item.label} primaryTypographyProps={primaryTypographyProps}/>
                            </ListItem>
                        )
                    }
                    {
                        Boolean(adminMenu.length) &&
                        <ListItemAdmin />
                    }
                    {/* {
                        // TODO: put this part dynamic like above
                        Boolean(canAccessRecord)&&
                        <ListItemRecord />
                    } */}
                </List>
                <ReferralPlanMssg />
                <Divider className="bg-fill-stroke-200"/>
                <List className="py-0">
                    <LogOut component={ListItem}>
                        <ListItemText primary="Cerrar sesión" className="flex-none offset-margin-right-12" primaryTypographyProps={primaryTypographyProps}/>
                        <ListItemIcon>
                            <Icon className="ri-logout-box-r-line font-size-13 text-gray-400"/>
                        </ListItemIcon>
                    </LogOut>
                </List>
            </StyledPopover>
        </>
    );
};

const mapStateToProps = (reducers) => ({
    ...reducers.userReducer, 
    ...reducers.permissionReducer, 
    ...reducers.menuReducer,
});

const mapDispatchToProps = { ...menuActions, setActiveTab };

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);

