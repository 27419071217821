import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// @material-ui
import Box from '@material-ui/core/Box';

// components 
import AccountForms from './Workflows/AccountForms/AccountForms';
import PaymentMethods from './Workflows/PaymentMethods/PaymentMethods';

// ds
import objectIsEmpty from '../../../design-system/utils/objectIsEmpty';

// shared
import { CHECKOUT_WORKFLOWS } from '../../../shared/constants/checkout';
import { generateCheckoutWorkflow } from '../../../shared/utils/checkoutHelpers';

const CheckoutWorkflow = ({acceptPayment, setAcceptPayment, setHandleFinalizePurchase}) => {
    const { personInfo } = useSelector(state => state.checkoutReducer);
    const [currentStep, setCurrentStep] = useState(CHECKOUT_WORKFLOWS.ACCOUNT_FORM);

    const nextStep = () => {
        setCurrentStep((prevStep) => prevStep + 1);
    };

    React.useEffect(() => {
        let initialStep = generateCheckoutWorkflow();
        setCurrentStep(initialStep)
    }, []);

    React.useEffect(() => {
        if (personInfo && !objectIsEmpty(personInfo)) {
            setCurrentStep(CHECKOUT_WORKFLOWS.PAYMENT_METHODS);
        }
    }, [personInfo]);

    return (
        <Box>
            {currentStep === CHECKOUT_WORKFLOWS.ACCOUNT_FORM && <AccountForms nextStep={nextStep} />}
            {currentStep === CHECKOUT_WORKFLOWS.PAYMENT_METHODS && <PaymentMethods nextStep={nextStep} acceptPayment={acceptPayment} setAcceptPayment={setAcceptPayment} setHandleFinalizePurchase={setHandleFinalizePurchase}/>}
        </Box>
    );
};

export default CheckoutWorkflow;