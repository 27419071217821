import React from 'react';
import PropTypes from 'prop-types';

import SearchImage from '../../../assets/images/empty-courses.svg';
import DataImage from '../../../assets/images/undraw_no_data.svg';
import ItemsImage from '../../../assets/images/undraw_empty.svg';
import CelebrationImage from '../../../assets/images/undraw_celebration_0jvk.svg';
// import NoNotificationsImage from '../../../assets/images/undraw_no_notifications.svg';
import NoNotificationsImage from '../../../assets/images/load-notificaciones.svg';
import CompletedCourses from '../../../assets/images/empty-courses.svg';
// import ShoppingBag from '../../../assets/images/undraw_empty.svg';
import InProgressCourses from '../../../assets/images/empty-courses.svg';
import Courses from "../../../assets/images/empty-courses.svg";
import PurchasedCourses from '../../../assets/images/undraw_education.svg';
import Timeout from '../../../assets/images/undraw_time_management.svg';
import NoAsesores from '../../../assets/images/undraw_asesorias.svg';
import NoMisAsesorias from '../../../assets/images/undraw_misasesorias.svg';

import { Box, Typography, Container } from '@material-ui/core';


const NothingFound = ({caption, subcaption, type, width = '300px', children}) => {   
    var image = null;
    switch(type){
        case "data":
            image = DataImage;
            break;
        case "items":
            image = ItemsImage;
            break;
        case "celebration":
            image = CelebrationImage;
            break;
        case "notifications":
            image = NoNotificationsImage;
            break;
        case "courses":
            image = Courses;
            break;
        case "completed-courses"||"courses-completed":
            image = CompletedCourses;
            break;
        case "in-progress-courses":
            image = InProgressCourses;
            break;
        case "purchased-courses":
            image = PurchasedCourses;
            break;
        case "timeout":
            image = Timeout;
            break;
        case "asesores":
            image = NoAsesores;
            break;
        case "misasesorias":
            image = NoMisAsesorias;
            break;
        default:
            image = SearchImage;
            break;
    }
    
    return ( 
        <Container maxWidth="lg">
            <Box display="flex" justifyContent="center" flexDirection="column" className="my-4">
                <Box display="flex" justifyContent="center" className="mb-4">
                    <img alt={caption + " "+subcaption} src={image} draggable="false" style={{ maxWidth: width }} />
                </Box>
                <Box display="flex" justifyContent="center" textAlign="center" flexDirection="column">
                    <Typography variant="subtitle1" className="mb-1" color="primary">{caption}</Typography>
                    <Typography variant="body1">{subcaption}</Typography>
                </Box>
                <Box display="flex" justifyContent="center" textAlign="center" flexDirection="column">
                    {children}
                </Box>
            </Box>
        </Container>
    );
};

NothingFound.defaultProps = {
    caption: 'Sin Contenido',
    subcaption: 'Intenta más tarde',
    type: 'search'
};

NothingFound.propTypes = {
    caption: PropTypes.string,
    subcaption: PropTypes.string,
    type: PropTypes.oneOf([
        "search",
        "data",
        "items",
        "celebration",
        "notifications",
        "shopping-bag",
        "courses",
        "completed-courses",
        "in-progress-courses",
        "purchased-courses",
        "timeout",
        "asesores",
        "misasesorias"
    ])
};
export default NothingFound;