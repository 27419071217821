import React from 'react';
import PropTypes from 'prop-types';

import { Button, Icon } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

// styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useStyles from '../../../assets/styles/jss/components/slickCarouselStyle'

//import { log } from '../../../shared/utils/console.js'
// components
// Core modules imports are same as usual
import SwiperCore, { Navigation, Pagination, Controller } from 'swiper';
// Direct React component imports
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

// Styles must use direct files imports
import 'swiper/swiper.scss'; // core Swiper

const SlickCarousel = ({ children, CustomButtonGroup, hiddeArrows, typeCard, length, changeDragStatus, isAliados, isDetail, ...rest }) => {
    const classes = useStyles({ isAliados, isDetail });
    const [hidde_Arrows, setHidde_Arrows] = React.useState(false);
    const [hidde_Left, setHidde_Left] = React.useState(true);
    const [hidde_Right, setHidde_Right] = React.useState(true);
    const sliderRef = React.useRef(null);

    const handlePrev = React.useCallback(() => {
        if (!sliderRef.current) return;
        setHidde_Right(true);
        sliderRef.current.swiper.slidePrev();
        sliderRef.current.swiper.activeIndex == 0 && setHidde_Left(true);
      }, []);
    
      const handleNext = React.useCallback(() => {
        if (!sliderRef.current) return;
        setHidde_Left(false);
        sliderRef.current.swiper.slideNext();
        sliderRef.current.swiper.isEnd && setHidde_Right(false);
      }, []);

    const handleResize = () => {
        if(typeCard == "aliados" || typeCard == "microgrados"){
            const size = (length * 419) + 200;
            //log(size);
            if (window.innerWidth <= size) {
                setHidde_Arrows(true);
            } else {
                setHidde_Arrows(false);
            }
        }else if(typeCard == "progress"){
            setHidde_Arrows(true); 
            //log("progress");
            //log(length);
        }else{
            let size = 0;
            //log(length);
            if(window.innerWidth >= 1550){
                size = (length * 314) + 185;
                //log(size);
                if (window.innerWidth <= size || length === 4) {
                    setHidde_Arrows(true);
                } else {
                    setHidde_Arrows(false);
                } 
            }else{
                size = (length * 314);
                //log(size);
                if (window.innerWidth <= size || length === 4) {
                    setHidde_Arrows(true);
                } else {
                    setHidde_Arrows(false);
                }  
            } 
        }
        
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
            abortController.abort();
          }
    }, []);

    const RenderArrows = () => {
        if(!length){
            return null;
        }
        
        return (
          <div className={classes.sliderArrow} id="root-slik-arrows">
            {!hidde_Left && <IconButton
              className="arrow-btn prev offset-margin-right-15"
              onClick={(e) => handlePrev(e)}
            >
                <Icon className="ri-arrow-left-line font-size-22" />
            </IconButton>}
            {hidde_Right && <IconButton
              className="arrow-btn next"
              onClick={(e) => handleNext(e)}
            >
              <Icon className="ri-arrow-right-line font-size-22" />
            </IconButton>}
          </div>
        );
    };

    return (
        <div className={`${classes.root} slick_carousel position-relative`}>
            <RenderArrows />
            
            <Swiper
                className={classes.slider}
                lazy={true}
                ref={sliderRef}
                slidesPerView={"auto"}
                modules={[Controller, Navigation, Pagination ]}
                navigation={{
                    clickable: true,
                }}
                {...rest}
            >
                {children}
            </Swiper>
            
        </div>
    );
};

SlickCarousel.propTypes = {
    children: PropTypes.node.isRequired,
};

// SlickCarousel.defaultProps = {
//     infinite: false,
//     speed: 300,
//     slidestoshow: 1,
//     slidestoscroll: 1,
// };

SlickCarousel.defaultProps = {
    infinite: 'false',
    speed: 300,
};

export default SlickCarousel;