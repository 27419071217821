import React from 'react';

// @material-ui
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Redirect } from 'react-router';
import { userIsLogged } from '../../../shared/utils/Sessions';

const useStyles = makeStyles((theme) => ({
    root: {
        bottom: 0,
        height: '90%',
        left: 0,
        position: 'absolute',
        right: 0,
        top: "8%",
        width: '100%',
    },
    pseudoMenu: {
        backgroundColor: '#111111', // Se puso fijo ya que se empata contra el color de los cursos en Easygenerator.
        backgroundPositionX: '50%',
        backgroundPositionY: '50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '15px',
        borderRadius: '8px',
        float: 'right',
        height: '9vh',
        position: 'absolute',
        top: '0.5vh',
        right: '1vh',
        width: '14vh',
        zIndex: '1',
    },
    loading: {
        height: '5px !important',
        position: 'absolute',
        textAlign: 'center',
        top: '50%',
        width: '100%',
    },
    iframe: {
        border: '0px',
        height: '100%',
        width: '100%',
    }
}));

const TalisisLearning = (props) => {
    const URL = `https://elearning.easygenerator.com/${props?.match?.params?.id}/`;
    const classes = useStyles();
    const [loading, setLoading] = React.useState(true);
    const loggedIn = userIsLogged()

    React.useEffect(() => {
        for (let elementId of ["drawerHeader", "footer"]) {
            document.getElementById(elementId).style.display = "none";
        }
    }, []);
    
    if (!loggedIn) {
        return <Redirect to={`/login?redirect=/cursos/cursar/${props?.match?.params?.id}`} />;
    }

    return (
        <Box className={classes.root}>
            <div className={classes.pseudoMenu}></div>
            {loading && <Typography component="div" variant="caption" className={classes.loading}>Espere un momento porfavor...</Typography>}
            <iframe id="talisis_eg_iframe" src={URL} allowFullScreen className={classes.iframe} loading="lazy" onLoad={() => setLoading(false)}></iframe>
        </Box>
    )
};

export default TalisisLearning;
