import React, { useState } from 'react';

import { 
    Button,
    Dialog,
    DialogTitle,
    Typography,
    Box,
    Icon,
    DialogContent,
    DialogActions,
    Card,
    CardContent
} from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';

import Select from '../../../../design-system/components/Form/Select/Select';
import getImageFromAssets from '../../../../design-system/utils/getImageFromAssets';
import RequestTitleForm from './components/RequestTitleForm';
import LoaderContent from '../../../../design-system/components/Loader/LoaderContent';
import Toast from '../../../../shared/components/Toast/Toast';

import { RecordDocumentService } from '@sdk-point/talisis';
const RecordDocumentServiceSDK = new RecordDocumentService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => ({
    dialogTitle:{
        display: 'flex',
        flexDirection: 'column',
        gap: 8
    },
    dialogContainer: {
        maxWidth: 630,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        }
    },
    bodyDialogContainer: {
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        }
    },
    approvedImg:{
        display: 'flex',
        margin: 'auto',
        width: 130
    }
}));

const RequestTitle = ({user}) => {
    const classes = useStyles();
    const [programs, setPrograms] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [confirmName, setConfirmName] = useState(false);
    const [form, setForm] = React.useState({
        "programSelected": "",
        "name": "",
        "lastName": "",
        "secondLastName": "",
        term1: false,
        term2: false
    });
    const [toastProps, setToastProps] = React.useState({
        message: 'Ocurrió un error inesperado. Por favor, inténtalo de nuevo más tarde',
        severity: 'error'
    });

    React.useEffect(() => {
        getPrograms()
    }, []);

    const getPrograms = async () =>{
        try {
            const data = await RecordDocumentServiceSDK.getRecordCertificateRequest(user.person_id);
            setPrograms(data)        
        } catch (error) {
            console.log('error', error)
        }
    }

    React.useEffect(() => {
        const _form = {
            "programSelected": programs.length === 1 ?  programs[0].id : "",
            "name": "",
            "lastName": "",
            "secondLastName": "",
            term1: false,
            term2: false
        }
        setForm(_form)

    }, [programs])

    const handleClickModal = () => {
        setOpenModal(prev => !prev)
    };

    const handleClose = () => {
        setOpenModal(prev => !prev);
        setConfirmName(false);
    }

    const handleConfirmName = async () => {
        const { programSelected, name, lastName, secondLastName } = form;
        setLoading(true)
        try {
            
            const { documents, template_id } = programs.find(it => it.id === programSelected) || [];
            const documentId = documents ? documents.find(it => it.type === 2)?.id : null;
            
            if(documentId){
                const body = {
                    "document_id": documentId,
                    "program_id": form.programSelected,
                    "accept_term_doc_expedida": 1,
                    "accept_term_tramites_sol": 1,
                    "display_name": name,
                    "display_last_name": lastName,
                    "display_second_last_name": secondLastName,
                    "templateId": template_id
                }
    
                await RecordDocumentServiceSDK.createRecordCertificateRequest(user.person_id, body);

                setPrograms(programs.filter(it => it.id !== programSelected));
                setLoading(false);
                handleClose();
            }
        } catch (error) {
            setToastProps({ ...toastProps, open: true})
            setLoading(false);
        }

    };

    const disabledButton = Object.values(form).some(value => !value);
    const handleChangeForm = (event) => {
        let {name, value, checked, type} = event.target;
        const _value = type === 'checkbox' ? checked : value;
        event.persist();

        setForm((form) => ({
            ...form,
            [name]: _value
        }));
    };

    if(!programs.filter(it => it.activate_process).length) return null;

    return (
        <div>
            <Card>
                <CardContent component={Box} display='flex' flexDirection="column" gridGap={24}>

                    <Box display='flex' flexDirection="column" gridGap={8}>
                        <Typography variant='h4'>Inicia el trámite de tu título</Typography>
                        {
                            form.programSelected &&
                            <Typography variant='h4' className='text-violet-300'>
                                {programs.find(it => it.id === form.programSelected)?.label}
                            </Typography>
                        }
                        <Typography variant='body2'>Ya estamos listos para iniciar tu proceso de Certificación y Titulación.</Typography>
                    </Box>

                    <Box>
                        <img src={getImageFromAssets('themeApproved')} className={classes.approvedImg}/>
                    </Box>

                    <Box display='flex' flexDirection="column" gridGap={8}>
                        {
                            programs.length > 1 &&
                            <Select
                                label='Selecciona el programa'
                                value={form.programSelected}
                                name="programSelected"
                                placeholder="Selecciona el programa"
                                items={programs} 
                                onChange={handleChangeForm}
                            />
                        }
                        <Button variant='contained' color='primary' fullWidth onClick={handleClickModal} disabled={!form.programSelected}>Solicitar trámite de título</Button>
                    </Box>

                </CardContent>
            </Card>

            <Dialog aria-labelledby="simple-dialog-title" open={openModal} classes={{paper: classes.dialogContainer}}>
                <LoaderContent loading={loading}>
                    <DialogTitle id="simple-dialog-title" disableTypography className={classes.dialogTitle}>
                        <Typography variant="h4">Finaliza la de entrega de documentos</Typography>
                        <Box display='flex' gridGap={16} alignItems="center">
                            <Icon className='ri-information-line text-warning-300 font-size-16'/>
                            <Typography variant='body2' className='text-gray-100'>Bajo protesta de decir verdad manifiesto que, de acuerdo a mi Acta de Nacimiento y documentación, mi(s) nombre(s) y apellidos utilizando mayúsculas, minúsculas y acentos deben aparecer de la siguiente manera:</Typography>
                        </Box>
                    </DialogTitle>

                    <DialogContent className={classes.bodyDialogContainer}>
                        <RequestTitleForm form={form} onChangeForm={handleChangeForm} readOnly={confirmName}/>
                    </DialogContent>

                    <DialogActions>
                        {
                            confirmName
                            ?<Box p={2}>
                                <Button variant='outlined' color='secondary' onClick={() => setConfirmName(false)} className='mr-2'>Editar</Button>
                                <Button variant='contained' color='primary' onClick={handleConfirmName}>Confirmar y enviar</Button>
                            </Box>
                            :<Box p={2}>
                                <Button variant='outlined' color='secondary' onClick={handleClickModal} className='mr-2'>Volver</Button>
                                <Button variant='contained' color='primary' disabled={disabledButton} onClick={() => setConfirmName(true)}>Aceptar</Button>
                            </Box>
                        }
                    </DialogActions>
                </LoaderContent>
            </Dialog>
            <Toast {...toastProps} />

        </div>
    );
};

export default RequestTitle;