/* eslint-disable */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import CurrencyFormat from 'react-currency-format';

// @material-ui/core
import { Card, CardContent, CardHeader, makeStyles, Typography, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { convertSourceToImageResource } from '../../../../../../src/constants/placeholderImages';

import { useTheme } from '@material-ui/core/styles';
import typography from '../../../../../design-system/theme/typography';
import FAQsCourse from '../../../../../shared/components/FAQsCourse/FAQsCourse';
import { SOURCES } from '../../../../../shared/constants/sources';
import { log } from '../../../../../shared/utils/console.js'

// @material-ui/lab
// import Alert from '@material-ui/lab/Alert';

// @material-ui/icons
// import EqualizerIcon from '@material-ui/icons/Equalizer';
// import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
// import EventIcon from '@material-ui/icons/Event';
// import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
// import TimerIcon from '@material-ui/icons/Timer';
// import FileCopyIcon from '@material-ui/icons/FileCopy';

// assets
// import LogoUerreImg from "../../../../../assets/images/logos/logo-uerre-blanco.png";
// import LogoUnidImg from "../../../../../assets/images/logos/logo-unid-blanco.png";
//import LogoHarmonHallImg from "../../../../../assets/images/logos/logo-harmon-hall.png";
// import LogoHarmonHallImg from "../../../../../assets/images/logos/cards-ico/harmonhall.svg";
// import LogoAdvenioImg from "../../../../../assets/images/logos/landing-marcas/Advenio_a.svg";

// components
// import FavoriteButton from '../../../CourseCatalog/CourseCard/FavoriteButton/FavoriteButton';
import ReadMore from '../../../../../shared/components/ReadMore/ReadMore';

//SDK
// import { MIXPANEL_EVENT } from '@sdk-point/talisis';
// import { Mixpanel } from '../../../../../shared/components/Mixpanel/mixpanel';
import CourseSelling from './CourseSelling/CourseSelling';
import CourseSubscription from './CourseSubscription/CourseSubscription';
import AccessInstructions from './AccessInstructions/AccessInstructions.jsx';

const useStyles = makeStyles((theme) => ({
    brand: {
        fontSize: '24px',
        fontWeight: '700',
        paddingBottom: '16px',
    },
    price: {
        fontSize: '24px',
        fontWeight: '700',
        paddingTop: '4px',
        paddingBottom: '4px',
        color: `${theme.palette.colors.orange[300]}`
    },
    responsive_price: {
        paddingTop: '4px',
        paddingBottom: '4px',
        color: `${theme.palette.colors.orange[300]} !important`,
    },
    favoriteButton: {
        marginRight: "10px",
        opacity: "0",
        pointerEvents: "none"
    },
    markerButton: {
        // backgroundColor: theme.palette.colors.grey[400],
        backgroundColor: theme.palette.colors.electricBlue[300],
        fontWeight: "bold",
        flexGrow: 1,
        fontSize: "13px",
        minWidth: '20px',
        padding: "0px 5px",
        marginLeft: "12px",
        // "&:hover span svg":
        // {
        //     color: "#2C3236"
        // }
    },
    cardContent: {
        // padding: "1.5rem !important",
        position: 'relative',
        // paddingTop: "0px !important"
    },
    button: {
        background: theme.palette.colors.orange[100],
        fontSize: 11,
        color: theme.palette.colors.white,
        padding: "2px 0px",
        lineHeight: '16px',
        marginLeft: 0,
        borderRadius: 2
    },
    cardBorder: {
        backgroundColor: '#1C1F24',
        boxShadow: '0px 0px 40px 0px rgba(0, 0, 0, 0.40)'
    },
    subscribe: {
        borderRadius: '8px',
        fontWeight: '400',
        fontSize: '14px',
        height: '40px',
        lineHeight: '40px',
        padding: "0px",
    },
    saveIcon: {
        borderRadius: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: "24px",
        maxWidth: "24px",
        height: "24px",
        backgroundColor: "transparent !important",
        boxShadow: "none",
        marginTop: "auto",
        marginBottom: "auto",
        '&:hover': {
            backgroundColor: '#99999996 !important',
            cursor: "pointer",
        },
    },
    lockButton: {
        padding: '8px 14px',
        backgroundColor: 'black',
        borderRadius: '8px',
        color: `${theme.palette.colors.fillStroke[100]}`
    },
    modal: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'auto',
        paddingTop: '58px',
        paddingLeft: '104px',
        paddingRight: '107.14px',
        border: '0.5px solid #404150',
        borderRadius: '8px',
        backgroundColor: 'rgba(0, 0, 0, 0.2)'
    },
    modalInside: {
        backgroundColor: `${theme.palette.colors.fillStroke[400]}`,
        "@media (max-width: 510px)": {
            paddingLeft: '10px',
            paddingRight: '10px'
        }
    },
    starIcon: {
        width: '20px',
        height: '19px',
        fontSize: '13px',
        marginRight: '9px',
        background: 'linear-gradient(273.67deg, #FCDC43 -55.56%, #FFA700 71.54%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
    },
    arrowIcon: {
        fontSize: '9px'
    },
    modalFooter: {
        color: `${theme.palette.colors.darkBlack[200]}`
    },
    helpCardHeader: {
        borderBottom: `1px solid ${theme.palette.colors.grey[400]}`,
    },
    helpCardContent: {
        display: 'inline-flex',
    },
    buttonCheckout: {
        color:theme.palette.colors.darkBlack[200]
    },
    customMembershipMessage:{
        ...theme.typography.bodyTextSmallRegular,
        color: `${theme.palette.colors.gray[500]} !important`
    },
    customMembershipMessageBorder:{
        borderBottom: `1px solid ${theme.palette.colors.fillStroke[200]}`,
        paddingBottom: '24px',
        [theme.breakpoints.down('sm')]:{
            borderBottom: 'none',
            paddingBottom:'0px'
        }
    },
    customMembershipMessageContainer:{
        marginBottom:'24px',
        [theme.breakpoints.down('sm')]:{
            marginTop:'24px',
            marginBottom:'0px'
        }
    }
}));

const EnrollSidebar = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    const myRef = React.useRef(null);
    const { membershipData, course /*available, user, onCheckoutClick, onMyCoursesClick,*/ /*previewMode, scrollPosition*/ } = props;
    const {user, isLogged} = useSelector((state) => state.userReducer);
    const [backgroundImage, setBackgroundImage] = React.useState(null);
    // let durationIcon = null;
    // let durationDesc = null;
    // let logo = null;

    let spliter = course.duration;
    if (spliter) {
        spliter = spliter.toString().split(' ');
        if (spliter[1] === "horas") {
            spliter[1] = "hrs";
            if (spliter[0] === 1) {
                spliter[1] = "hra";
            }
            spliter = spliter.join(" ");
        }
    }

    if (course.learning_style_id === 1) {

        if (course.start_date != null && course.end_date != null) {
            // const date1 = moment(course.start_date);
            // const date2 = moment(course.end_date);

            // durationIcon = <EventIcon fontSize="small" />;
            // durationDesc = `${moment.duration(date2.diff(date1)).asWeeks().toFixed(0)} semanas`;
        } else {
            // durationIcon = <TimerIcon fontSize="small" />;
            // durationDesc = `${spliter} aprox.`;
        }

    } else {
        // durationIcon = <TimerIcon fontSize="small" />;
        // durationDesc = `${spliter} aprox.`;
    }

    const setData = async () => {
        return course?.image_url != null ? course?.image_url : convertSourceToImageResource(course?.company_id, course?.source_id);
    }

    React.useEffect(() => {
        let isCanceled = true;
        setData().then((data) => {
            if (isCanceled) {
                setBackgroundImage(data);
            }
        }).catch((err) => {
            log(`Failed: ${err}`);
        });
        return () => (isCanceled = false);
    }, [course]);

    return (
        <React.Fragment>
            <div className={props.class}>
                <Card className={classes.cardBorder}>
                    <CardContent className="p-3" style={{ position: 'relative' }}>
                        <div ref={myRef}>
                            <Grid item xs={12}>
                                <Box className="px-2">
                                    <Box display="flex" flexDirection="column" flexWrap="nowrap">
                                        {course.source_is_internal || course.source_id === SOURCES.EASYGENERATOR || (course.source_id === SOURCES.IE_UNIVERSITY && course.company_id === "UERRE") ?
                                            <CourseSelling course={course} classes={classes} membershipData={membershipData} backgroundImage={backgroundImage} />
                                            : <CourseSubscription course={course} classes={classes} membershipData={membershipData} backgroundImage={backgroundImage} />
                                        }
                                    </Box>
                                </Box>
                            </Grid>
                        </div>
                    </CardContent>
                </Card>
                {
                    isLogged &&
                    <AccessInstructions sourceId={course.source_id} membership={user.membership_id}/>
                }
                <Card className='mt-3'>
                    <FAQsCourse title={'¿Necesitas ayuda?'} />
                </Card>
            </div>

        </React.Fragment>
    );
};

export default EnrollSidebar;
